import styled from 'styled-components'
import { Button } from '@material-ui/core'

import { mediaQuery } from '../../../../styles/helpers/mediaQuery'

export const StatusButtonStyle = styled(Button)`
  border-radius: 12px;
  width: 85px;
  font-weight: bold;

  & > .MuiButton-label {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    text-transform: initial;
  }

  & .MuiButton-iconSizeMedium {
    margin-right: 4px;

    & > *:first-child {
      font-size: 16px;
    }
  }

  &.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }

  &.it {
    &--statusButtom--approved {
      color: #ffffff;
      background-color: #418564;
    }

    &--statusButtom--added {
      color: #ffffff;
      background-color: #5375db;
    }

    &--statusButtom--add {
      color: #404040;
      background-color: #f8d05e;
    }
  }

  ${mediaQuery('sm')`
          width: 92px;

          & > .MuiButton-label{
            font-size: 16px;
            line-height: 16px;
          }

          & .MuiButton-iconSizeMedium > *:first-child {
            font-size: 20px;
          }
        `}
`
