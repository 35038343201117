import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { breakpoints, mediaQuery } from '../../../../styles/helpers/mediaQuery'

export const InspectionItemStyle = styled(Grid)`
  background-color: #efeff3;
  border-radius: 10px; 
  margin-bottom: 12px;
  min-height: 110px;
  padding: 12px;
  width: 100%;

  display: grid;
  grid-template-columns: 52px 1fr 24px;
  grid-template-rows: 1fr 48px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  .inspectionItem--thumbnailSection { 
    display: flex;
    grid-area: 1 / 1 / 3 / 2; 
    justify-content: center;
    padding-top: 4px;
  }

  
  .inspectionItem--footer { 
    display: flex;
    grid-area: 2 / 2 / 3 / 4; 
    justify-content: space-between;
    align-items: center;

    & > .inspectionItem--costLabel{
      color: #616161;
      font-family: 'Helvetica';
      font-size: 14px;
      font-weight: bold;

      & > span{
        font-weight: initial;
        margin-left: 4px;
      }
    }
  }

  .inspectionItem--descriptionSection { 
    font-family: 'Helvetica';
    grid-area: 1 / 2 / 2 / 3; 
    & > p{
      word-break: break-word;
      font-weight: bold;
    } 

    & > p:first-child{
      color: #17152a;
      font-size: 16px;
      margin-bottom: 8px;
    }

    & > p:nth-child(2){
      font-size: 12px;
      color: #7a7a7a;
    } 
  }
  .inspectionItem--expandMoreIconSection { 
    grid-area: 1 / 3 / 2 / 4; 
    & > button{
      color: #1572f4;
      padding: 0;
    }
  }

  .inspectionItem--availableSection { 
    font-weight: normal;
  }

  .inspectionItem--subItemSection{
    border-top: 1px solid #fafafa;
    margin: 0 -12px;
    padding: 16px 12px 0 12px;
    grid-area: 3 / 1 / 4 / 4;
    overflow: hidden;

    & > p {
      color: #7a7a7a;
      font-family: 'Helvetica';
      font-size: 12px;
      margin-bottom: 24px;
      text-transform: capitalize;

      & > span {
        display: inline-block;
        width: 50%;
        word-break: break-word;
      }
    }

    & > p:last-child {
      margin-bottom: 0;
    }
  } 
  
  .inspectionItem--expandMoreIconSection { 
    
    & .MuiSvgIcon-root{
      font-size: 24px;
    }
  }
  
  ${mediaQuery('sm')`
    background-color: #ffffff;
    grid-template-columns: 72px 1fr 24px;
    grid-template-rows: 152px 48px;
    height: max-content;
    margin: 16px 8px;
    max-height: 228px;

    .inspectionItem--footer { 
      align-items: center;
      justify-content: space-between;

      & > .inspectionItem--costLabel{
        font-size: 16px;
      }
    }

    .inspectionItem--descriptionSection {
      margin-bottom: 12px;

      & > p:first-child{
        font-size: 18px;
        margin-bottom: 12px;
      }

      & > p:nth-child(2){
        font-size: 14px;
      } 
    }
    .inspectionItem--subItemSection > p {
        font-size: 14px;
        margin-bottom: 14px;
    } 
    `}

  @media (max-width: 800px) and (min-width: ${breakpoints.sm}px){
    grid-template-rows: 92px 48px;
    max-width: 100%;
  }

  ${mediaQuery('md')`
    grid-template-columns: 64px 1fr 24px;
    height: max-content;
    max-height: 228px;
    min-width: 31%;
    padding: 16px;

    `}

  @media (max-width: 1060px) and (min-width: ${breakpoints.md}px){
    grid-template-columns: 60px 1fr 24px;
    grid-template-rows: 152px 52px;
    max-width: 100%;
    padding: 8px;

    .inspectionItem--descriptionSection {
      margin-bottom: 12px;

      & > p:first-child{
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
    
    .inspectionItem--footer { 
      align-items: center;
      grid-area: 2 / 1 / 3 / 4; 
      justify-content: space-around;
    }
  }

  ${mediaQuery('lg')`
      max-width: 23%;
      min-width: 23%;
    `}

  @media (max-width: 1400px) and (min-width: ${breakpoints.lg}px){ 
    grid-template-rows: 172px 63px;
    max-height: 260px;

    .inspectionItem--footer { 
      align-items: center;
      grid-area: 2 / 1 / 3 / 4; 
      justify-content: space-around;
    }
  }
`
