import styled from 'styled-components'

import { mediaQuery } from '../../styles/helpers/mediaQuery'
import backgroundSquareOne from '../../assets/images/background-square-one.png'
import backgroundSquareTwo from '../../assets/images/background-square-two.png'

export const NotFoundStyle = styled.div`
  align-items: center;
  background-color: #efeff3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;

  background-image: url(${backgroundSquareOne}), url(${backgroundSquareTwo});
  background-position: 97% 100px, 4% 97%;
  background-repeat: no-repeat;

  #errorCode-section {
    font-size: 32px;
    margin-bottom: 24px;
  }

  #message-section {
    text-align: center;
  }

  #message-expression {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  #message-text {
    font-size: 18px;
  }

  ${mediaQuery('sm')`
      background-position: 80% 100px, 32% 97%
    `}
`
