import React, { Suspense } from 'react'
import { Provider } from 'unstated'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'

import { Root } from './views/Root'
import { BackButton } from './components/BackButton'
import { GlobalStyle } from './styles/Global'

/** Unstated */
// We make classes instance of container to make aggregation of state between differents containes
import { InspectionItemContainer } from './containers/inspectionItem.container'
import { RepairShopContainer } from './containers/repairShop.container'
import { RepairOrderContainer } from './containers/repairOrder.container'

const repairShopContainer = new RepairShopContainer()
const inspectionItemContainer = new InspectionItemContainer(repairShopContainer)
const repairOrderContainer = new RepairOrderContainer(
  repairShopContainer,
  inspectionItemContainer
)

/** Unstated */
const styleNode = document.createComment('jss-insertion-point')
document.head.insertBefore(styleNode, document.head.firstChild)

const jss = create({
  ...jssPreset(),
  insertionPoint: 'jss-insertion-point',
})

export const App = () => {
  return (
    <Suspense fallback={BackButton}>
      <StylesProvider jss={jss}>
        <GlobalStyle />
        <Provider
          inject={[
            repairOrderContainer,
            inspectionItemContainer,
            repairShopContainer,
          ]}
        >
          <Root />
        </Provider>
      </StylesProvider>
    </Suspense>
  )
}
