import React from 'react'
import { Router } from '@reach/router'

import { Home } from './Home'
import { WorkshopInformation } from './WorkshopInformation'
import { InspectionItems } from './InspectionItems'
import { NotFound } from './NotFound'

function Root() {
  return (
    <Router>
      <Home path="/digital-repair-order" />
      <WorkshopInformation path="/digital-repair-order/workshop-information" />
      <InspectionItems path="/digital-repair-order/inspection-items" />
      <NotFound path="/digital-repair-order/not-found" />
      <NotFound default />
    </Router>
  )
}

export { Root }
