import styled from 'styled-components'
import { mediaQuery } from '../../../../styles/helpers/mediaQuery'

export const InspectionItemsRecapStyle = styled.div`
  width: inherit;
  
  ${mediaQuery('xs')`   
    width:100%;
    display:flex;
    flexWrap:nowrap;
    overflow-x:scroll;
    `}
  ${mediaQuery('sm')`
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
    flex-wrap: nowrap;
    overflow-x:hidden;
    `}

  ${mediaQuery('md')`
    margin-left: 80px;
    margin-right: 80px;
    `}

  ${mediaQuery('lg')`
    margin-left: 104px;
    `}
`
