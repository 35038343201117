import styled from 'styled-components'

import { breakpoints } from '../../../../styles/helpers/mediaQuery'

const priorityColor = {
  urgent: '#e84661',
  good: '#25bc73',
  suggested: '#dbb012',
}

export const CardStyle = styled.div`
  background-color: #efeff3;
  border-radius: 10px;
  height: 164px;
  margin: 0 8px;
  padding: 16px;
  width: 136px;
  min-width: 136px;

  .inspectionItemCard {
    &--section-content {
      height: 100px;
      margin-bottom: 20px;
    }
    &--section-action {
      & p {
        color: #121a41;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    &--items-amount {
      border-radius: 4px;
      background-color: ${(props) => priorityColor[props.priority]};
      margin-bottom: 12px;
      height: 51px;
      width: 51px;

      & > p {
        color: #f4f4f9;
        font-family: 'Helvetica';
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        padding: 15% 0 0 0;
        line-height: 32px;
      }
    }

    &--items-type {
      & p:first-child {
        margin-bottom: 4px;
      }
      & p:nth-child(2) {
        word-break: break-word;
        color: ${(props) => priorityColor[props.priority]};
      }
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    background-color: #ffffff;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
    height: 151px;
    margin: 4px;
    padding: 16px 8px;
    width: 220px;
    cursor: pointer;

    .inspectionItemCard {
      &--section-content {
        display: flex;
        margin-bottom: 36px;
        height: 70px;
      }

      &--section-action {
        & .MuiSvgIcon-root {
          margin-bottom: -4px;
        }
      }

      &--items-amount {
        margin-bottom: 0;
        margin-right: 8px;
        height: 60px;
        width: 60px;

        & > p {
          font-size: 28px;
          padding: 25% 0 0 0;
          line-height: 28px;
        }
      }

      &--items-type {
        font-family: 'Helvetica';

        & p:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    height: 215px;
    margin: 20px 16px;
    padding: 16px 24px 8px 24px;
    width: 251px;

    &:first-child {
      margin-left: 0;
    }

    .inspectionItemCard {
      &--section-content {
        flex-direction: column;
        margin-bottom: 16px;
        height: initial;
      }

      &--section-action {
        font-size: 16px;

        & .MuiSvgIcon-root {
          margin-bottom: -4px;
        }
      }

      &--items-amount {
        margin-bottom: 12px;
        margin-right: 0px;
        height: 78px;
        width: 78px;

        & > p {
          font-size: 32px;
          padding-top: 32%;
          line-height: 32px;
        }
      }

      &--items-type {
        & p:first-child {
          margin-bottom: 4px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    height: 235px;
    width: 251px;

    .inspectionItemCard {
      &--section-content {
        margin-bottom: 28px;
      }

      &--items-amount {
        margin-bottom: 16px;
      }

      &--items-type {
        & p:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }
`
