import { Container } from 'unstated'
import { StatusKarloService } from '../services/StatusKarlo.service'

class StatusKarloContainer extends Container {
  constructor() {
    super()
    this.statusKarloService = new StatusKarloService()
    this.state = {
      data: {},
      loadedData: false,
      loadingData: false,
    }
  }

  async getPaymentStatus(token) {
    var res = null
    this.setState({ loadingData: !this.state.loadedData })
    await this.statusKarloService
      .cmPaymentStatus(
        this.state.data.organizationID,
        this.state.data.orderID,
        token
      )
      .then((data) => {
        res = {
          ...data,
          loadedData: !this.state.loadedData,
          loadingData: !this.state.loadedData,
        }
        this.setState(res)
      })
      .finally(() => {
        this.setState({ loadingData: !this.state.loadedData })
      })
    return res
  }
  async getData(repairShopContainer, repairOrderContainer) {
    this.setState({ loadingData: true })
    var data = {
      orderID: repairOrderContainer
        ? repairOrderContainer.state.repairOrderNumber
        : '',
      organizationID: repairShopContainer
        ? repairShopContainer.state.karloAccount
        : '',
      emailUserLoginKarlo: repairShopContainer
        ? repairShopContainer.state.emailUserLoginKarlo
        : '',
      passwordUserLoginKarlo: repairShopContainer
        ? repairShopContainer.state.passwordUserLoginKarlo
        : '',
    }
    await this.setState({ data: data })
    return this.statusKarloService.userLogin(
      this.state.data.emailUserLoginKarlo,
      this.state.data.passwordUserLoginKarlo
    )
  }
}

export { StatusKarloContainer }
