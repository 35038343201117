import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { LabelDefault } from '../../../../components/Labels'
import { ShoppingCartStyle } from './styles'
import { ThumbnailImage } from '../../../../components/ThumbnailImage'
import { withConnect } from '../../../../HOC/withConnect'
import { withQueryString } from '../../../../HOC/withQueryString'
import { RepairShopContainer } from '../../../../containers/repairShop.container'
import str from '../../../../utils/str'

function ShoppingCartStateLees({ containers, response, isMobile }) {
  const [RepairShopContainer] = containers
  if (
    response == null ||
    !response.loadedData ||
    !RepairShopContainer.state.showKarloPaymentLink
  )
    return <div></div>
  const { t } = useTranslation()
  const statuses = {
    '': '',
    Facturada: 'invoiced',
    'Por pagar': 'toPay',
    Pagada: 'paid',
    Abierta: 'open',
  }
  var r = {
    message: '',
    result: {
      url: '',
      status: '',
      paymentDate: null,
      subtotal: 0,
    },
  }

  if (response.message == 'Ok') {
    r = response
  }
  status = r.result.status !== '' ? statuses[r.result.status] : ''
  var enableButton =
    statuses[r.result.status] == 'toPay' ||
    statuses[r.result.status] == 'invoiced' ||
    statuses[r.result.status] == 'paid'
  var textButton =
    statuses[r.result.status] == 'toPay' || statuses[r.result.status] == 'open'
      ? t('shoppingCart.pay')
      : t('shoppingCart.details')
  const disabledStyle = {
    color: 'rgba(0,0,0,0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0,0,0,0.12)',
  }
  const primarySyle = {
    backgroundColor: '#121a41',
  }
  const buttonStyle = {
    float: 'right',
    minWidth: '100px',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '16px',
    width: '100%',
    paddingBottom: '10px',
  }
  if (isMobile) {
    return (
      <Button
        onClick={() => window.open(r.result.url, '_blank')}
        variant="contained"
        disabled={!enableButton}
        style={{
          ...(enableButton ? primarySyle : disabledStyle),
          ...buttonStyle,
          float: 'right',
          minWidth: '100px',
          borderRadius: '16px',
        }}
      >
        {textButton}
      </Button>
    )
  }
  return (
    <ShoppingCartStyle display={{ sm: 'none', md: 'block' }}>
      <section className="shoppingCard--section-content">
        <div className="shoppingCard--items-type">
          <ThumbnailImage
            iconType="Cart"
            src=""
            className="thumbnailColor--primary thumbnail--small backgroundDefault"
          />
          <LabelDefault
            size="s0"
            bold={true}
            style={{ marginTop: '0.8rem', color: '#121a41' }}
          >
            {t('shoppingCart.paymentInformation')}
          </LabelDefault>
          <LabelDefault size="s0" bold={false} style={{ marginTop: '1.2rem' }}>
            {t('shoppingCart.status')}: {t('shoppingCart.statuses.' + status)}
          </LabelDefault>
          <LabelDefault size="s0" bold={false}>
            Subtotal:{' '}
            {str.format(
              RepairShopContainer.state.currency,
              str.numberFormat(
                r.result.subtotal,
                2,
                3,
                RepairShopContainer.state.groupSeparator,
                RepairShopContainer.state.decimalSeparator
              )
            )}
          </LabelDefault>
        </div>
      </section>
      <section className="shoppingCard--section-action">
        <Button
          style={{
            ...(enableButton ? primarySyle : disabledStyle),
            ...buttonStyle,
          }}
          onClick={() => window.open(r.result.url, '_blank')}
          variant="contained"
          disabled={!enableButton}
        >
          {textButton}
        </Button>
      </section>
    </ShoppingCartStyle>
  )
}

ShoppingCartStateLees.propType = {
  containers: PropTypes.array,
  response: PropTypes.array,
  isMobile: PropTypes.bool,
}

const ShoppingCart = withConnect([RepairShopContainer])(
  withQueryString(ShoppingCartStateLees)
)
export { ShoppingCart }
