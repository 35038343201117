import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Match } from '@reach/router'
import { Grid, IconButton, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
  Menu as MenuIcon,
  ReplyOutlined as ReplyOutlinedIcon,
} from '@material-ui/icons'

import { BackButton } from '../BackButton'
import { SideMenu } from '../SideMenu'
import { WorkshopLogo } from '../WorkshopLogo'
import { NavBarStyle, NavBarToolbarStyle, NavBarGridDro } from './styles'

import { withConnect } from '../../HOC/withConnect'
import { RepairShopContainer } from '../../containers/repairShop.container'
import { RepairOrderContainer } from '../../containers/repairOrder.container'

import SelectData from '../../utils/SelectData'

function TopBarStateLess({ containers }) {
  const theme = useTheme()
  const [stateSideMenu, setStateSideMenu] = useState(false)
  const isSMXLViewport = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const [RepairShopContainer, RepairOrderContainer] = containers
  const { t } = useTranslation()
  const srcLogo = SelectData.selectLogo(
    RepairShopContainer.state.showVehicleMakeLogo,
    RepairOrderContainer.state.makeLogoUrl,
    RepairShopContainer.state.consumerFormLogo
  )
  const Icon = isSMXLViewport ? ReplyOutlinedIcon : undefined
  const backButtonLabel = isSMXLViewport
    ? t('common.button.backButtonLabel')
    : ''

  const toggleDrawer = (e) => {
    setStateSideMenu(!stateSideMenu)
  }
  const toggleDrawerFromMenu = (flag) => {
    setStateSideMenu(flag)
  }

  return (
    <>
      <NavBarStyle position="fixed">
        <NavBarToolbarStyle component="nav">
          <NavBarGridDro
            alignItems="center"
            container
            justify="space-between"
            wrap="nowrap"
          >
            <Grid item xs={2} sm={1}>
              <Match path="/digital-repair-order/">
                {({ match, location }) => {
                  return match ? (
                    <IconButton
                      style={{ padding: 0 }}
                      aria-label="menu"
                      color="inherit"
                      edge="end"
                      id="header--navbarbutton"
                      onClick={toggleDrawer}
                    >
                      <MenuIcon />
                    </IconButton>
                  ) : (
                    <BackButton
                      to={'/digital-repair-order/' + location.search}
                      label={backButtonLabel}
                      id="header--previousButton"
                      icon={Icon}
                    />
                  )
                }}
              </Match>
            </Grid>
            <Grid item xs={8} sm={8} id="header--workshop--nameArea">
              <h4 id="workshop--nameLabel">
                {RepairShopContainer.state.accountNameDisplayed}
              </h4>
            </Grid>
            <Grid container justify="center" item xs={1} sm={1}>
              <WorkshopLogo
                alt="Workshop logo"
                src={srcLogo}
                variant="rounded"
              />
            </Grid>
          </NavBarGridDro>
        </NavBarToolbarStyle>
      </NavBarStyle>
      <SideMenu toggleDrawer={toggleDrawerFromMenu} open={stateSideMenu} />
    </>
  )
}

TopBarStateLess.propTypes = {
  containers: PropTypes.array,
}

export const TopBar = withConnect([RepairShopContainer, RepairOrderContainer])(
  TopBarStateLess
)
