import { BaseService } from './Base.service'

class RepairShopService extends BaseService {
  getRepairShop(id) {
    return this.get('repair-shop/' + id)
      .then((response) => {
        return response.data.payload.repairShop
      })
      .catch(() => console.error('We have had a error'))
  }
}

export { RepairShopService }
