import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Add as AddIcon } from '@material-ui/icons'

import { StatusButtonStyle } from './styles'

const statuses = {
  approved: {
    class: 'it--statusButtom--approved',
    labelkeyT: 'inspectionItems.inspectionItem.approvedStatusButtonLabel',
    key: 'approved',
    icon: null,
  },
  added: {
    class: 'it--statusButtom--added',
    labelkeyT: 'inspectionItems.inspectionItem.addedStatusButtonLabel',
    key: 'added',
    icon: null,
  },
  add: {
    class: 'it--statusButtom--add',
    labelkeyT: 'inspectionItems.inspectionItem.addStatusButtonLabel',
    key: 'add',
    icon: <AddIcon />,
  },
}
function StatusButton({ disabled = false, status = 'add', onItemStatusClick }) {
  const { t } = useTranslation()
  const cursor = statuses.approved.key !== status ? 'pointer' : 'default'
  const handleClick = () => {
    let statusToSet = ''
    if (statuses.approved.key !== status) {
      statusToSet =
        statuses.add.key === status ? statuses.added.key : statuses.add.key

      onItemStatusClick(statusToSet)
    }
  }

  return (
    <StatusButtonStyle
      style={{ cursor }}
      className={`inspectionItem--statusButtom ${statuses[status].class}`}
      startIcon={statuses[status].icon}
      variant="contained"
      disabled={disabled && statuses.approved.key !== status}
      onClick={handleClick}
    >
      {t(statuses[status].labelkeyT)}
    </StatusButtonStyle>
  )
}

StatusButton.propTypes = {
  disabled: PropTypes.bool,
  status: PropTypes.string,
  onItemStatusClick: PropTypes.func,
}

export { StatusButton }
