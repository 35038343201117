import styled from 'styled-components'
import { Button } from '@material-ui/core'

import { mediaQuery } from '../styles/helpers/mediaQuery'

const chipButtonStyle = {
  default: {
    key: 'default',
    styles: {
      backgroundcolor: '#a6a6a6',
    },
  },
  primary: {
    key: 'primary',
    styles: {
      backgroundcolor: '#1572f4',
    },
  },
}

function setChipButtonStyle({ type = chipButtonStyle.primary.key }) {
  return chipButtonStyle[type].styles
}

export const ChipButton = styled(Button).attrs(setChipButtonStyle)`
  color: #ffffff;
  border-radius: 59px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.backgroundcolor};
  /*height: 40px;*/
  width: 100%;

  & .MuiButton-label {
    color: #ffffff;
    font-family: 'Helvetica';
    font-size: 12px;
    text-transform: initial;
  }

  & .MuiButton-startIcon {
    margin-right: 8px;

    & > *:first-child {
      font-size: 20px;
    }
  }

  &.Mui-disabled {
    background-color: #a6a6a6;
  }

  ${mediaQuery('sm')`
    & .MuiButton-label{
        font-size: 16px;
      }

      & .MuiButton-startIcon{
        margin-right: 8px;

        & > *:first-child{
          font-size: 24px;
        }
      }
    `}

  ${mediaQuery('md')`
    & .MuiButton-label{
        font-size: 16px;
      }

      & .MuiButton-startIcon{
        margin-right: 4px;

        & > *:first-child{
          font-size: 18px;
        }
      }
    `}
`
