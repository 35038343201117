import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import {
  Assignment as AssignmentIcon,
  LinearScale as LinearScaleIcon,
} from '@material-ui/icons'

import { MainSectionStyle } from '../../components/MainSection'
import { FooterBar } from '../../components/FooterBar'

import { TopBar } from '../../components/TopBar'
import { RepairOrderRecap } from './components/RepairOrderRecap/'
import { ShoppingCart } from './components/ShoppingCart/'
import { InspectionItemsRecap } from './components/InspectionItemsRecap/'
import { LabelButton } from './components/LabelButton'
// import { ChipButton } from '../../components/ChipButton'
import { LabelDefault, LabelPrimary } from '../../components/Labels'

import { HomeGridStyle } from './styles'

import { withMainData } from '../../HOC/withMainData'
import { withConnect } from '../../HOC/withConnect'

import { getServiceAdvisorPhone } from '../../utils/getServiceAdvisorPhone'
import str from '../../utils/str'
import { StatusKarloContainer } from '../../containers/statusKarlo.container'

class HomeView extends Component {
  renderTotalApprovedLabel({ t, approvedTotalLabel, karloAmmount }) {
    return (
      <>
        <LabelDefault size="s1">
          {karloAmmount
            ? 'Subtotal'
            : t('home.inspectionItemsRecap.totalInfoCardMainLabel')}
        </LabelDefault>
        <LabelPrimary>{approvedTotalLabel}</LabelPrimary>
      </>
    )
  }

  async getKarloToken(
    statusKarloContainer,
    repairShopContainer,
    repairOrderContainer
  ) {
    var token = null
    var ret = statusKarloContainer.getData(
      repairShopContainer,
      repairOrderContainer
    )
    await ret.then((data) => {
      token = data.session.token
    })
    return token
  }

  async getKarloData(statusKarloContainer, token) {
    var res = null
    var ret = statusKarloContainer.getPaymentStatus(token)
    await ret.then((data) => {
      res = data
    })
    return ret
  }

  componentDidUpdate(prevProps) {
    if (prevProps.repairOrderData.loadedData) {
      const [
        RepairOrderContainer,
        RepairShopContainer,
        AdditionalNoteContainer,
        StatusKarloContainer,
      ] = this.props.containers
      if (
        !StatusKarloContainer.state.loadingData &&
        !StatusKarloContainer.state.loadedData
      ) {
        this.getKarloToken(
          StatusKarloContainer,
          RepairShopContainer,
          RepairOrderContainer
        ).then((statusKarloToken) => {
          this.getKarloData(StatusKarloContainer, statusKarloToken)
        })
      }
    }
  }

  render() {
    const { t } = this.props
    document.title = t('common.tab.title')
    const TotalApprovedLabel = this.renderTotalApprovedLabel
    const [
      RepairOrderContainer,
      RepairShopContainer,
      AdditionalNoteContainer,
      StatusKarloContainer,
    ] = this.props.containers
    const phoneNumberToUse = getServiceAdvisorPhone(
      RepairOrderContainer.state.teamMember.mobileNumber,
      RepairOrderContainer.state.teamMember.landlineNumber,
      RepairShopContainer.state.phone
    )

    const _n = RepairShopContainer.state.removeDecimals ? 0 : 2
    const approvedTotalStr = str.format(
      RepairShopContainer.state.currency,
      str.numberFormat(
        RepairOrderContainer.state.totalApproved,
        _n,
        3,
        RepairShopContainer.state.groupSeparator,
        RepairShopContainer.state.decimalSeparator
      )
    )

    console.log('Additional notes:' + AdditionalNoteContainer.state)

    let itemForLabelButton = [
      {
        labelKeyT: 'home.documentsSection.repairOrderLabeButtonLabel',
        icon: AssignmentIcon,
        dlResource: {
          src: RepairOrderContainer.state.pdfUrl,
          name: RepairOrderContainer.state.repairOrderNumber + '-RepairOrder',
        },
      },
    ]
    if (RepairShopContainer.state.workshopPlannerDealerID) {
      itemForLabelButton.push({
        labelKeyT: 'home.documentsSection.statusRepairLabeButtonLabel',
        icon: LinearScaleIcon,
        dlResource: {
          src: `https://${RepairShopContainer.state.subdomain}.clearmechanic.com/dashboard/status/orders/${RepairOrderContainer.state.repairOrderNumber}?organizationId=${RepairShopContainer.state.workshopPlannerDealerID}`,
          name: RepairOrderContainer.state.repairOrderNumber + '-RepairOrder',
        },
        newTab: true,
      })
    }

    return (
      <>
        <TopBar />
        <MainSectionStyle container direction="column">
          <Grid item xs={12}>
            <HomeGridStyle container>
              <Grid item xs={12} sm={11} md={11} lg={9}>
                <RepairOrderRecap
                  consumerName={RepairOrderContainer.state.firstName}
                  consumerLastName={RepairOrderContainer.state.lastName}
                  roNumber={RepairOrderContainer.state.repairOrderNumber}
                  make={RepairOrderContainer.state.make}
                  model={RepairOrderContainer.state.model}
                  year={RepairOrderContainer.state.year}
                  plates={RepairOrderContainer.state.plates}
                  mileage={RepairOrderContainer.state.mileage}
                  vin={RepairOrderContainer.state.vin}
                  showConsumerInformation={
                    RepairShopContainer.state.showConsumerInformation
                  }
                />
              </Grid>
              <Grid item md={1} lg={1}>
                <ShoppingCart response={StatusKarloContainer.state} />
              </Grid>
              <Grid container item wrap="nowrap" xs={12}>
                <InspectionItemsRecap
                  repairsAmount={RepairOrderContainer.state.repairsAmount}
                  totalInfoCardAmount={approvedTotalStr}
                  serviceAdvisorPhone={phoneNumberToUse}
                />
              </Grid>
              <Grid className="homeGrid--documentsSection" container item>
                <Grid container item className="links">
                  {itemForLabelButton.map((item, i) => (
                    <LabelButton
                      key={`LB-${i}`}
                      label={t(item.labelKeyT)}
                      icon={item.icon}
                      dlResource={item.dlResource}
                      newTab={item.newTab}
                    />
                  ))}
                </Grid>
                {AdditionalNoteContainer.state.additionalNotes.length > 0 && (
                  <Grid item className="additionalNotes">
                    <div style={{ fontWeight: 'bold' }}>
                      {t('home.documentsSection.notes') + ': '}
                    </div>
                    <div>
                      {AdditionalNoteContainer.state.additionalNotes.map(
                        (note, index) => (
                          <div key={index}>{'- ' + note.text}</div>
                        )
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
              {!(
                StatusKarloContainer.state == null ||
                !StatusKarloContainer.state.loadedData ||
                !RepairShopContainer.state.showKarloPaymentLink
              ) ? (
                <Grid className="homeGrid--footerSection" container item>
                  <Grid container item>
                    <div className="text">
                      <b>{t('shoppingCart.securePayment')}</b>{' '}
                      {t('shoppingCart.with')} <b>Karlo</b>
                      <b style={{ color: 'transparent' }}>__</b>
                    </div>
                    <div className="logo"></div>
                  </Grid>
                </Grid>
              ) : null}
            </HomeGridStyle>
          </Grid>
        </MainSectionStyle>
        <FooterBar
          // TODO: Temporal por ticket  CM-4513

          // button={
          //   <ChipButton
          //     component='a'
          //     href={'tel:' + phoneNumberToUse}
          //     variant='contained'
          //     size='small'
          //     startIcon={<LocalPhoneIcon />}
          //   >
          //     {t('home.inspectionItemsRecap.totalInfoCardButtonLabel')}
          //   </ChipButton>
          // }
          button={
            <ShoppingCart
              response={StatusKarloContainer.state}
              isMobile={true}
            />
          }
          informationLabels={
            StatusKarloContainer.state == null ||
            !StatusKarloContainer.state.loadedData ||
            !RepairShopContainer.state.showKarloPaymentLink ? (
              <TotalApprovedLabel
                t={t}
                approvedTotalLabel={approvedTotalStr}
                karloAmmount={false}
              />
            ) : (
              <TotalApprovedLabel
                t={t}
                karloAmmount={true}
                approvedTotalLabel={str.format(
                  RepairShopContainer.state.currency,
                  str.numberFormat(
                    StatusKarloContainer.state.result.subtotal,
                    2,
                    3,
                    RepairShopContainer.state.groupSeparator,
                    RepairShopContainer.state.decimalSeparator
                  )
                )}
              />
            )
          }
        />
      </>
    )
  }
}

export const Home = withConnect()(
  withTranslation()(
    withMainData(HomeView, { containers: [StatusKarloContainer] })
  )
)
