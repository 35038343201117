import styled from 'styled-components'

import { breakpoints, mediaQuery } from '../../styles/helpers/mediaQuery'

const labelSizeStyles = {
  s0: {
    fontSize: {
      xs: '10px',
      sm: '12px',
      md: '14px',
    },
  },
  s1: {
    fontSize: {
      xs: '12px',
      sm: '16px',
      md: '20px',
    },
  },
  s2: {
    fontSize: {
      xs: '16px',
      sm: '20px',
      md: '24px',
    },
  },
  s3: {
    fontSize: {
      xs: '20px',
      sm: '24px',
      md: '28px',
    },
  },
}

function getLabelSizeStyles({ size = 's2', bold = true }) {
  return {
    bold: bold ? 'bold' : 'normal',
    fontSize: labelSizeStyles[size].fontSize,
  }
}

export const LabelDefault = styled.p.attrs(getLabelSizeStyles)`
  color: #85818c;
  font-size: ${(props) => props.fontSize.xs};
  font-weight: ${(props) => props.bold};
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
  word-break: break-word;

  @media (min-width: ${breakpoints.sm}px) {
    font-size: ${(props) => props.fontSize.sm};
  }

  @media (min-width: ${breakpoints.md}px) {
    font-size: ${(props) => props.fontSize.md};
  }
`

export const LabelPrimary = styled.p.attrs(getLabelSizeStyles)`
  color: #17152a;
  font-size: ${(props) => props.fontSize.xs};
  font-weight: ${(props) => props.bold};
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
  word-break: break-word;

  @media (min-width: ${breakpoints.sm}px) {
    font-size: ${(props) => props.fontSize.sm};
  }

  @media (min-width: ${breakpoints.md}px) {
    font-size: ${(props) => props.fontSize.md};
  }
`

export const LabelTitlePrimary = styled(LabelDefault)`
  color: #17152a;
  font-size: 24px;
  font-weight: bold;

  ${mediaQuery('sm')`
    font-size: 32px;
  `}

  ${mediaQuery('md')`
    font-size: 36px;
  `}
`
