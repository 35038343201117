import styled from 'styled-components'
import { Button } from '@material-ui/core'

import { mediaQuery } from '../../styles/helpers/mediaQuery'

export const BackButtonStyled = styled(Button)`
  &.MuiButton-root {
    color: #616161;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  & .MuiButton-label {
    font-size: 16px;
    font-weight: bold;
    text-transform: initial;
  }

  & .MuiButton-startIcon {
    margin-right: 4px;
  }

  & .MuiButton-iconSizeLarge > *:first-child {
    font-size: 14px;
  }

  ${mediaQuery('md')`
    & .MuiButton-label{
      font-size: 20px;
    }

    & .MuiButton-startIcon{
      margin-right: 8px;
    }
    
    & .MuiButton-iconSizeLarge > *:first-child{
      font-size:16px;
    }
    `}
`
