export const fontFace = function (
  name,
  src,
  fontWeight = 'normal',
  fontStyle = 'normal'
) {
  return `
      @font-face{
          font-family: "${name}";
          src: url(${src}) format("truetype");
          font-style: ${fontStyle};
          font-weight: ${fontWeight};
      }
  `
}
