import styled from 'styled-components'
import { Grid, CircularProgress } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'

import { mediaQuery } from '../../../../styles/helpers/mediaQuery'

export const ApprovalModalContentStyle = styled(Grid)`
  min-width: 320px;
  padding: 44px 32px 20px 32px;
  position: relative;
  flex-wrap: noWrap;

  .approvalModalContent--iconSection {
    background-color: #0048c0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -20px;
  }

  .approvalModalContent--titleSection {
    color: #17152a;
    font-family: 'Lato';
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;
    text-align: center;
  }

  .approvalModalContent--inspectionItemsSection {
    overflow: scroll;
    max-height: calc(100vh - 315px);

    & > .approvalModalContent--itemsWrapper {
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        & > p {
          font-family: 'Lato';
          font-size: 16px;
          color: #121a41;
          padding-left: 8px;
          text-transform: capitalize;
        }

        & > p + p {
          padding-right: 8px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }

  .approvalModalContent--totalSection {
    background-color: #efeff3;
    border-radius: 4px;
    margin-bottom: 32px;

    & > p {
      font-family: 'Lato';
      font-size: 16px;
      color: #121a41;
      padding-left: 8px;
      text-transform: capitalize;
      padding-right: 8px;
      font-weight: bolder;
      padding-bottom: 4px;
      padding-top: 4px;
    }

    & > p + p {
      text-align: center;
    }
  }

  .approvalModalContent--button {
    border-radius: 4px;
    font-family: 'Lato';
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
  }

  .AMC--button {
    &--approve {
      color: #ffffff;
      background-color: #0048c0;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      margin-bottom: 20px;
    }

    &--cancel {
      background-color: #ffffff;
      color: #17152a;
      box-shadow: none;
    }
  }

  ${mediaQuery('xs')`
      .AMC--button{
        &--approve{
          margin-bottom: 10px;
        }
      }
    `}

  ${mediaQuery('sm')`
      min-width: 450px;

      .approvalModalContent--titleSection{
        font-size: 24px;
        line-height: 28px;
      }

      .approvalModalContent--inspectionItemsSection{
        max-height: 280px;
        height: calc(100vh - 350px);
        & > .approvalModalContent--itemsWrapper{
          
          & > div{
            & > p {
              font-size: 18px;
              padding-left: 12px;
            }

            & > p + p{
              padding-right: 12px;
            }
          }
        }
      }
      .approvalModalContent--totalSection{
          margin-bottom: 24px;

          & > p {
            font-size: 18px;
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 8px;
            padding-top: 8px;
          }
      }

      .approvalModalContent--button{
        font-size: 16px;
      }
      .AMC--button{
        &--approve{
          margin-bottom: 20px;
        }
      }
    `}
`

export const CheckCircleOutlineIconStyle = styled(CheckCircleOutline)`
  color: #25bc73;
  font-size: 40px;
  margin: 10px auto;
`
export const CircularProgressStyle = styled(CircularProgress)`
  color: #0048c0;
`
