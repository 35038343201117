import styled from 'styled-components'
import { Avatar } from '@material-ui/core'

import { breakpoints } from '../styles/helpers/mediaQuery'

const avatarSizeStyle = {
  small: {
    key: 'small',
    style: {
      height: {
        xs: '36px',
        sm: '40px',
        md: '44px',
      },
      width: {
        xs: '36px',
        sm: '40px',
        md: '44px',
      },
    },
  },
  medium: {
    key: 'medium',
    style: {
      height: {
        xs: '40px',
        sm: '46px',
        md: '52px',
      },
      width: {
        xs: '40px',
        sm: '46px',
        md: '52px',
      },
    },
  },
  large: {
    key: 'large',
    style: {
      height: '32px',
      width: '32px',
    },
  },
}

function setAvatarSizeStyle({ size = avatarSizeStyle.medium.key }) {
  return {
    height: avatarSizeStyle[size].style.height,
    width: avatarSizeStyle[size].style.width,
  }
}

export const WorkshopLogo = styled(Avatar).attrs(setAvatarSizeStyle)`
  height: ${(props) => props.height.xs};
  width: ${(props) => props.width.xs};

  & > img {
    object-fit: contain;
  }

  @media (min-width: ${breakpoints.sm}px) {
    height: ${(props) => props.height.sm};
    width: ${(props) => props.width.sm};
  }

  @media (min-width: ${breakpoints.md}px) {
    height: ${(props) => props.height.md};
    width: ${(props) => props.width.md};
  }
`
