import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { TopBar } from '../../components/TopBar'
import { MainSectionStyle } from '../../components/MainSection'
import { InformationArea } from '../../components/InformationArea'
import { LabelPrimary } from '../../components/Labels'
import { WorkshopLogo } from '../../components/WorkshopLogo'
import { WorkshopInformationContainerStyle } from './styles'

import { withMainData } from '../../HOC/withMainData'

import SelectData from '../../utils/SelectData'

class WorkshopInformationView extends Component {
  render() {
    const { t } = this.props
    const [RepairOrderContainer, RepairShopContainer] = this.props.containers
    const srcLogo = SelectData.selectLogo(
      RepairShopContainer.state.showVehicleMakeLogo,
      RepairOrderContainer.state.makeLogoUrl,
      RepairShopContainer.state.consumerFormLogo
    )

    // {RepairShopContainer.state.email}
    return (
      <>
        <TopBar />
        <MainSectionStyle container direction="column">
          <WorkshopInformationContainerStyle
            container
            item
            xs={12}
            alignItems="flex-start"
          >
            <Grid
              className="workshopInformation--nameSection"
              item
              xs={12}
              sm={9}
              md={7}
              lg={6}
            >
              <WorkshopLogo
                alt="Workshop logo"
                variant="rounded"
                size="small"
                src={srcLogo}
              />
              <LabelPrimary size="s3">
                {t('workshopInformation.sectionTitle')}
              </LabelPrimary>
            </Grid>
            <Grid
              className="workshopInformation--informationSection"
              container
              item
              xs={12}
              md={7}
              sm={9}
              lg={6}
              direction="column"
            >
              <Grid item>
                <InformationArea
                  labelText={t(
                    'workshopInformation.informationSection.workshopNameLabel'
                  )}
                  bodyText={RepairShopContainer.state.accountNameDisplayed}
                />
              </Grid>
              <Grid item>
                <InformationArea
                  labelText={t(
                    'workshopInformation.informationSection.phoneNumberLabel'
                  )}
                >
                  <LabelPrimary>{RepairShopContainer.state.phone}</LabelPrimary>
                </InformationArea>
              </Grid>
              {/* <Grid item>
                <InformationArea labelText={t('workshopInformation.informationSection.emailLabel')} bodyText='soporte@clearcheck.us' />
              </Grid> */}
              <Grid item>
                <InformationArea
                  labelText={t(
                    'workshopInformation.informationSection.addressLabel'
                  )}
                  bodyText={t(
                    'workshopInformation.informationSection.addressTemplate',
                    {
                      address1: RepairShopContainer.state.address1,
                      address2: RepairShopContainer.state.address2,
                      city: RepairShopContainer.state.city,
                      state: RepairShopContainer.state.state,
                      zip: RepairShopContainer.state.zip,
                    }
                  )}
                />
              </Grid>
            </Grid>
          </WorkshopInformationContainerStyle>
        </MainSectionStyle>
      </>
    )
  }
}

export const WorkshopInformation = withTranslation()(
  withMainData(WorkshopInformationView)
)
