import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { useTheme } from '@material-ui/core/styles'
import { Divider, Grid, useMediaQuery } from '@material-ui/core'
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Close as CloseIcon,
} from '@material-ui/icons'

import { ThumbnailImage } from '../../../../components/ThumbnailImage'

import { EvidenceModalStyle, SliderIconButton } from './styles'

import { breakpoints } from '../../../../styles/helpers/mediaQuery'

import {
  MEDIA_TYPE_PHOTO,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_AUDIO,
} from '../../../../constant/mediaType'
import { DEFAULT_MEDIA_STATE } from '../../../../constant/schemasDefault'

import { showOverlay } from '../../../../utils/overlay'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const commentsAnnimationVariants = {
  swipeUp: {
    bottom: 0,
  },
  swipeDown: {
    bottom: '-81%',
  },
}
const mediaSliderSettings = {
  arrows: true,
  dots: false,
  infinite: false,
  slidesToShow: 6,
  swipeToSlide: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: breakpoints.sm,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
}

function PhotoMedia({ media, inspectioItemName }) {
  const figureRef = useRef()
  const imgRef = useRef()
  let tem = []
  const [markers, setMarkers] = useState([])

  const handleLoadImg = () => {
    if (media.overlays) {
      tem = showOverlay(
        imgRef.current,
        figureRef.current,
        media.originalWidth,
        media.overlays
      )
      setMarkers(tem)
    }
  }

  return (
    <figure ref={figureRef} className="evidenceMedia--image-wrapper">
      <div className="evidenceMedia--image--markers-overlay">
        {markers.map((item) => {
          return (
            <>
              <img
                src={item.markerSrc}
                style={{
                  top: item.imageY + 'px',
                  left: item.imageX + 'px',
                  width: item.size + 'px',
                }}
                className="markers-overlay"
              />
              {item.showNumber && (
                <span
                  style={{
                    top: item.numberY + 'px',
                    left: item.numberX + 'px',
                    fontSize: '14px',
                  }}
                  className="markers-overlay markers-overlay__number "
                >
                  {item.number}
                </span>
              )}
            </>
          )
        })}
      </div>
      <img
        ref={imgRef}
        src={media.filePath}
        alt={media.type + ' of ' + inspectioItemName}
        onLoad={handleLoadImg}
      />
    </figure>
  )
}

function EvidenceMediaDisplay({ element, inspectioItemName }) {
  if (element.type === MEDIA_TYPE_PHOTO) {
    return <PhotoMedia media={element} inspectioItemName={inspectioItemName} />
  } else if (element.type === MEDIA_TYPE_VIDEO) {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className="evidenceMedia--video-wrapper">
          <video
            controls
            key={element.filePath}
            poster={element.thumbnailFilePath}
          >
            <source src={element.filePath} type="video/mp4" />
          </video>
        </div>
      </div>
    )
  } else if (element.type === MEDIA_TYPE_AUDIO) {
    return (
      <div className="evidenceMedia--audio-wrapper">
        <audio controls key={element.filePath}>
          <source src={element.filePath} type="audio/mp3" />
        </audio>
      </div>
    )
  }

  return null
}

function SliderArrow({ arrowClass, side, onClick }) {
  const arialLabel = side === 'right' ? 'right arrow' : 'left arrow'
  const Icon = side === 'right' ? ArrowRightIcon : ArrowLeftIcon
  const positionClass =
    side === 'right' ? 'sliderArrow--right' : 'sliderArrow--left'

  return (
    <SliderIconButton
      aria-label={arialLabel}
      className={`${positionClass} ${arrowClass}`}
      onClick={onClick}
    >
      <Icon fontSize="large" />
    </SliderIconButton>
  )
}

function EvidenceModal({ item, isOpenModal, onClose }) {
  const theme = useTheme()
  const isMDXLViewport = useMediaQuery(theme.breakpoints.between('md', 'xl'))
  const { t } = useTranslation()
  const [mediaState, setMediaState] = useState(DEFAULT_MEDIA_STATE)
  const [showCommentsFullView, setShowCommentsFullView] = useState(false)

  useEffect(() => {
    if (item.media.length) {
      setMediaState(item.media[0])
    }
    if (!isOpenModal) {
      setMediaState(DEFAULT_MEDIA_STATE)
      setShowCommentsFullView(false)
    }
  }, [item, isOpenModal])

  const touggleCommentsFullView = () => {
    setShowCommentsFullView(!showCommentsFullView)
  }
  return (
    <EvidenceModalStyle container direction="column">
      <Grid
        className="evidenceModal--nameSection"
        container
        justify="center"
        item
        xs={12}
        md={7}
        lg={8}
      >
        <h3>{item.name}</h3>
        {!isMDXLViewport && (
          <button onClick={onClose}>
            <CloseIcon fontSize="large" className="em-closeIcon" />
          </button>
        )}
      </Grid>
      <Grid className="evidenceModal--mediaSection" item xs={12} md={7} lg={8}>
        <EvidenceMediaDisplay
          element={mediaState}
          inspectioItemName={item.name}
        />
      </Grid>
      <Grid
        className="evidenceModal--informationSection"
        container
        direction="column"
        item
        xs={12}
        md={7}
        lg={8}
      >
        <div className="evidenceModal--mediaSliderWrapper em--mediaSliderWrapper">
          {item.media.length ? (
            <Slider
              {...mediaSliderSettings}
              nextArrow={
                <SliderArrow arrowClass="sliderArrow--media" side="right" />
              }
              prevArrow={
                <SliderArrow arrowClass="sliderArrow--media" side="left" />
              }
            >
              {item.media.map((mediaItem, index) => (
                <div key={`EM-M-${index}`}>
                  <ThumbnailImage
                    className="em--SliderItem--Thumbnails"
                    iconType={mediaItem.type}
                    src={mediaItem.thumbnailFilePath}
                    onClick={() => setMediaState(mediaItem)}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <p className="em--noContent em--noContent--media">
              {t('inspectionItems.evidenceModal.mediaSectionNoMedia')}
            </p>
          )}
        </div>

        {/* aqui va todo  */}
        {isMDXLViewport ? null : (
          <>
            <Divider />
            <div className="evidenceModal--mediaSliderWrapper em--commentSliderWrapper">
              <div className="em--rowTitleComments">
                <p className="em-mediaSliderTitle">
                  {t('inspectionItems.evidenceModal.commentSectionTitle')}
                </p>
                {item.comments.length > 0 && (
                  <button
                    onClick={touggleCommentsFullView}
                    style={{ color: '#06c' }}
                  >
                    {t('inspectionItems.evidenceModal.showAllCommentsBtnLabel')}
                  </button>
                )}
              </div>
              {item.comments.length ? (
                item.comments.map((comment, i) => (
                  <>
                    <div className="em--commentWrapper" key={`EM-C-${i}`}>
                      <p>{comment.text}</p>
                    </div>
                    <Divider />
                  </>
                ))
              ) : (
                <p className="em--noContent em--noContent--comments">
                  {t('inspectionItems.evidenceModal.commentSectionNoComments')}
                </p>
              )}
            </div>
          </>
        )}
      </Grid>
      {isMDXLViewport ? (
        <>
          <Grid
            className="evidenceModal--commentsSection"
            container
            direction="column"
            item
            xs={12}
            md={5}
            lg={4}
          >
            <p className="em-mediaSliderTitle">
              {t('inspectionItems.evidenceModal.commentSectionTitle')}
            </p>
            {item.comments.length ? (
              <div className="evideceModal--commentsWrapper">
                {item.comments.map((comment, i) => (
                  <div className="em--commentWrapper" key={`EM-C-${i}`}>
                    <p>{comment.text}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="em--noContent em--noContent--comments">
                {t('inspectionItems.evidenceModal.commentSectionNoComments')}
              </p>
            )}
          </Grid>
        </>
      ) : null}
      {!isMDXLViewport && <div className="em--bottomGradient" />}
      {showCommentsFullView && (
        <motion.div
          initial="swipeDown"
          animate="swipeUp"
          variants={commentsAnnimationVariants}
          className="em-commentsFullView"
        >
          <div className="em--rowTitleComments">
            <p className="em-mediaSliderTitle">
              {t('inspectionItems.evidenceModal.commentSectionTitle')}
            </p>
            <button onClick={touggleCommentsFullView} style={{ color: '#06c' }}>
              {t('inspectionItems.approvalModal.closeBTNLabel')}
            </button>
          </div>
          {item.comments.map((comment, i) => (
            <>
              <div className="em--commentWrapper" key={`EM-C-${i}`}>
                <p>{comment.text}</p>
              </div>
              <Divider />
            </>
          ))}
        </motion.div>
      )}
    </EvidenceModalStyle>
  )
}

EvidenceModal.propTypes = {
  item: PropTypes.shape({
    comments: PropTypes.arrayOf(
      PropTypes.shape({
        repairId: PropTypes.number,
        id: PropTypes.number,
        text: PropTypes.string,
      })
    ),
    id: PropTypes.number,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        originalHeight: PropTypes.number,
        originalWidth: PropTypes.number,
        type: PropTypes.string,
        filePath: PropTypes.string,
        thumbnailFilePath: PropTypes.string,
        overlays: PropTypes.array,
      })
    ),
    name: PropTypes.string,
  }),
  isOpenModal: PropTypes.bool,
}

export { EvidenceModal }
