import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { mediaQuery } from '../../../../styles/helpers/mediaQuery'

export const TotalInfoCardStyle = styled(Grid)`
  background-color: #121a41;
  border-radius: 10px;
  margin: 4px;
  height: 151px;
  padding: 8px 16px;
  width: 200px;

  //TODO: Temporal por ticket  CM-4513
  display: flex;
  align-items: center;
  justify-content: center;

  & .totalInfoCardStyle {
    &--approvalTotalSection {
      margin-bottom: 8px;

      & p {
        color: #ffffff;
        padding-left: 8px;

        &:first-child {
          font-size: 16px;
          margin-bottom: 8px;
        }

        &:nth-child(2) {
          font-size: 20px;
          font-family: 'Helvetica';
        }
      }
    }

    &--contactSection {
      & > p {
        border-radius: 4px;
        background-color: rgba(218, 218, 218, 0.2);
        padding: 6px;
        margin-bottom: 8px;
        font-size: 14px;
        color: #ffffff;
      }

      & > button {
        /*background-color: #0048c0;*/
        & .MuiButton-label {
          font-family: 'Helvetica';
          font-size: 16px;
        }
      }
    }
  }

  ${mediaQuery('md')`
      margin: 20px 0 20px 16px;
      height: 215px;
      padding: 32px 24px 16px 24px;
      width: 288px;

      & .totalInfoCardStyle{
        &--approvalTotalSection{
          margin-bottom: 12px;
          
          & p{
            &:nth-child(2){
              font-size: 24px;
            }
          }
        }

        &--contactSection{
          & > p{
            padding: 12px 8px 12px 8px;
            margin-bottom: 12px;
            font-size: 20px;
          }
        }
        
      }
    `}

  @media (max-width: 1200px) and (min-width: 961px) {
    margin-left: 0;
  }

  ${mediaQuery('lg')`
      height: 235px;

      & .totalInfoCardStyle{
        &--approvalTotalSection{
          margin-bottom: 20px;
          
          & p{
            font-size: 20px;
            &:nth-child(2){
              font-size: 24px;
            }
          }
        }
        
        &--contactSection{
          & > p{
            padding: 12px;
            margin-bottom: 16px;
          }
        }
      }
    `}
`
