import { Container } from 'unstated'

import { InspectionItemService } from '../services/InspectionItem.service'

class InspectionItemContainer extends Container {
  constructor(repairShopContainer) {
    super()
    this.repairShopContainer = repairShopContainer
    this.inspectionItemService = new InspectionItemService(repairShopContainer)

    this.state = {
      urgent: [],
      low: [],
      suggested: [],
      loadedData: false,
      loadingData: false,
    }
    this.approvalStatus = {
      add: 'add',
      added: 'added',
      approved: 'approved',
    }
  }

  getData(roNumberEncode) {
    this.setState({ loadingData: !this.state.loadedData })
    this.inspectionItemService
      .getRepairs(roNumberEncode)
      .then((data) => {
        this.setState({
          ...data,
          loadedData: !this.state.loadedData,
          loadingData: !this.state.loadedData,
        })
      })
      .finally(() => {
        this.setState({ loadingData: !this.state.loadedData })
      })
  }

  getFormatedDataFromRepairs(repairs) {
    this.setState({ loadingData: !this.state.loadedData })
    var formattedRepairs = this.inspectionItemService.formatRepairs(repairs)
    this.setState({
      ...formattedRepairs,
      loadedData: !this.state.loadedData,
      loadingData: !this.state.loadedData,
    }).finally(() => this.setState({ loadingData: !this.state.loadedData }))
  }

  changeApprovalStatus(ids, newApprovalStatus) {
    ids = ids instanceof Array ? ids : [{ id: ids }]
    let itemIndexsArr = this._getIndexsItemsArrayToChangeStatus(
      this.state.urgent,
      ids
    )

    if (itemIndexsArr.length) {
      const updatedArray = this._changeStatusItems(
        [...this.state.urgent],
        itemIndexsArr,
        newApprovalStatus
      )
      this.setState({ urgent: updatedArray })
    }

    itemIndexsArr = this._getIndexsItemsArrayToChangeStatus(
      this.state.suggested,
      ids
    )

    if (itemIndexsArr.length) {
      const updatedArray = this._changeStatusItems(
        [...this.state.suggested],
        itemIndexsArr,
        newApprovalStatus
      )
      this.setState({ suggested: updatedArray })
    }
  }

  _getIndexsItemsArrayToChangeStatus(itemsArr, ids) {
    const itemIndexsArr = []

    for (let i = 0; i < ids.length; i++) {
      for (let j = 0; j < itemsArr.length; j++) {
        if (ids[i].id === itemsArr[j].id) {
          itemIndexsArr.push(j)
          break
        }
      }
    }

    return itemIndexsArr
  }

  _changeStatusItems(itemsArr, itemIndexsArr, status) {
    for (let i = 0; i < itemIndexsArr.length; i++) {
      itemsArr[itemIndexsArr[i]].approvalStatus = status
    }

    return itemsArr
  }
}

export { InspectionItemContainer }
