import React from 'react'
import PropTypes from 'prop-types'
import {
  ImageOutlined as ImageOutlinedIcon,
  ShoppingCartOutlined as CartIcon,
  PlayArrow as PlayArrowIcon,
  VolumeDown as VolumnDownIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
} from '@material-ui/icons'

import { ThumbnailImageStyle } from './styles'

import {
  MEDIA_TYPE_PHOTO,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_AUDIO,
} from '../../constant/mediaType'
import { ICON_CART } from '../../constant/iconType'

function ThumbnailImage({ className, iconType, src, size = 'small', onClick }) {
  let Icon = null
  switch (iconType) {
    case MEDIA_TYPE_PHOTO:
      Icon = ImageOutlinedIcon
      break
    case MEDIA_TYPE_VIDEO:
      Icon = PlayArrowIcon
      break
    case MEDIA_TYPE_AUDIO:
      Icon = VolumnDownIcon
      break
    case ICON_CART:
      Icon = CartIcon
      break
    default:
      Icon = VisibilityOffOutlinedIcon
      break
  }
  var backgroundDefault =
    typeof className === 'undefined'
      ? false
      : className.includes('backgroundDefault')
  return (
    <ThumbnailImageStyle
      className={
        className +
        (src !== '' || backgroundDefault ? '' : ' thumbnail--noBackground')
      }
      onClick={onClick}
      size={size}
    >
      {src === '' ? null : <img src={src} />}
      <Icon />
    </ThumbnailImageStyle>
  )
}

ThumbnailImage.propTypes = {
  className: PropTypes.string,
  iconType: PropTypes.string,
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func,
}

export { ThumbnailImage }
