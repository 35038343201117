import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'
import './locales/en-US/translation.json'
import './locales/es-MX/translation.json'
import './locales/pt-PT/translation.json'
import './locales/pt-BR/translation.json'

// import locale-data for needed lngs
import { en, es, pt } from 'i18next-icu/locale-data'

i18n
  .use(
    new ICU({
      localeData: [en, es, pt],
    })
  )
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    lng: 'es-MX',
    fallbackLng: 'en-US',
    debug: !(process.env.NODE_ENV === 'production'),
    supportedLngs: ['en-US', 'es-MX', 'pt-PT', 'pt-BR'],
    ns: ['translation'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
