import { BaseService } from './Base.service.js'
import { InspectionItemService } from './InspectionItem.service'
import { toFixed } from '../utils/calc'
const { round } = require('js-round')

class RepairOrderService extends BaseService {
  constructor(repairShopContainer) {
    super()

    this.inspectionItemService = new InspectionItemService(repairShopContainer)

    this.repairShopContainer = repairShopContainer
    this.priorityLavel = {
      low: 'Low',
      urgent: 'Urgent',
      suggested: 'Med',
    }

    this.priorityCount = {
      good: 0,
      suggested: 0,
      urgent: 0,
    }

    this.allRepairs = []
  }

  async getFullRepairOrder(roNumberEncode) {
    let data = {}
    try {
      const repairOrderResponse = await this.get(
        'full-repair-order/' + roNumberEncode
      )
      this.allRepairs = [...repairOrderResponse.data.repairs]
      if (repairOrderResponse.data.templateId) {
        await this.getFullTemplate(
          repairOrderResponse.data.templateId,
          repairOrderResponse.data.defaultPriorityLevel
        )
      }
      const repairsAmount = await this.countRepairs(this.allRepairs)
      const teamMember = await this.getTeamMember(
        repairOrderResponse.data.repairShopId,
        repairOrderResponse.data.inChargeUserId
      )
      var repairData = repairOrderResponse.data
      data = {
        ...repairData,
        repairOrderId: repairOrderResponse.data.repairOrderId,
        repairs: this.allRepairs,
        repairsAmount,
        teamMember,
        repairShop: repairOrderResponse.data.repairShop,
      }
    } catch (error) {
      throw new Error('Resource was not founded')
    }
    return data
  }

  async getFullTemplate(templateId, defaultPriority) {
    const fullRepair = await this.get('full-template/' + templateId)
    fullRepair.data.systems.map((system) =>
      system.masterItems.map((masterItem) =>
        this.createRpairObjectFromMasterItem(
          masterItem,
          defaultPriority,
          system
        )
      )
    )
  }

  createRpairObjectFromMasterItem(masterItem, defaultPriority, system) {
    let repair = this.allRepairs.find(
      (el) => el.masterItem.masterItemId === masterItem.masterItem.masterItemId
    )
    if (!repair) {
      let newRepair = {
        repairOrderId: null,
        id: null,
        priority: defaultPriority,
        isApproved: false,
        isDeclined: false,
        masterItem: {},
        isHiden: false,
        comments: [],
        mediaFiles: [],
        estimates: [],
        system: {},
      }

      newRepair.masterItem = { ...masterItem.masterItem }
      newRepair.masterItem.diagrams = masterItem.diagrams

      newRepair.system.templateId = system.templateId
      newRepair.system.systemId = system.systemId
      newRepair.system.name = system.name
      newRepair.system.sequenceNumber = system.sequenceNumber
      newRepair.system.isHiddenForConsumer = system.isHiddenForConsumer
      newRepair.system.masterItem = {}
      newRepair.system.masterItem.templateId = masterItem.templateId
      newRepair.system.masterItem.systemId = masterItem.systemId
      newRepair.system.masterItem.masterItemId = masterItem.masterItemId
      newRepair.system.masterItem.sequenceNumber = masterItem.sequenceNumber
      newRepair.system.masterItem.details = masterItem.details
      newRepair.system.masterItem.isHiddenForConsumer =
        masterItem.isHiddenForConsumer
      newRepair.system.masterItem.masterItem = {}
      newRepair.system.masterItem.masterItem.masterItemId =
        masterItem.masterItem.masterItemId
      newRepair.system.masterItem.masterItem.name = masterItem.masterItem.name

      this.allRepairs.push(newRepair)
    } else {
      repair.system = {}
      repair.system.templateId = system.templateId
      repair.system.systemId = system.systemId
      repair.system.name = system.name
      repair.system.sequenceNumber = system.sequenceNumber
      repair.system.isHiddenForConsumer = system.isHiddenForConsumer
      repair.system.masterItem = {}
      repair.system.masterItem.templateId = masterItem.templateId
      repair.system.masterItem.systemId = masterItem.systemId
      repair.system.masterItem.masterItemId = masterItem.masterItemId
      repair.system.masterItem.sequenceNumber = masterItem.sequenceNumber
      repair.system.masterItem.details = masterItem.details
      repair.system.masterItem.isHiddenForConsumer =
        masterItem.isHiddenForConsumer
      repair.system.masterItem.masterItem = {}
      repair.system.masterItem.masterItem.masterItemId =
        masterItem.masterItem.masterItemId
      repair.system.masterItem.masterItem.name = masterItem.masterItem.name
    }
  }

  checkRepairPriority(item) {
    let current = item.system || {
      isHiddenForConsumer: false,
      masterItem: { isHiddenForConsumer: false },
    }
    if (
      (current.isHiddenForConsumer === false &&
        current.masterItem.isHiddenForConsumer === false) & !item.isHidden
    ) {
      switch (item.priority) {
        case this.priorityLavel.low:
          this.priorityCount.good++
          break
        case this.priorityLavel.suggested:
          this.priorityCount.suggested++
          break
        case this.priorityLavel.urgent:
          this.priorityCount.urgent++
          break
        default:
          break
      }
    }
  }

  countRepairs(repairs) {
    repairs.forEach((item) => this.checkRepairPriority(item))
    let good = this.priorityCount.good
    let urgent = this.priorityCount.urgent
    let suggested = this.priorityCount.suggested
    return {
      good,
      urgent,
      suggested,
    }
  }

  async getRepairOrder(roNumberEncode) {
    let data = {}

    try {
      const repairOrderResponse = await this.get(
        'repair-order/' + roNumberEncode
      )
      const repairsAmount = await this.getRepairsAmount(roNumberEncode)
      const teamMember = await this.getTeamMember(
        repairOrderResponse.data.repairOrder.payload.repairOrder.repairShopId,
        repairOrderResponse.data.repairOrder.payload.repairOrder.inChargeUserId
      )

      data = {
        ...repairOrderResponse.data.repairOrder.payload.repairOrder,
        repairOrderId:
          repairOrderResponse.data.repairOrder.payload.repairOrderId,
        repairsAmount,
        teamMember,
      }
    } catch (error) {
      throw new Error('Resource was not founded')
    }
    return data
  }

  getRepairsAmount(id) {
    return this.get('repairs/' + id).then((response) => {
      let good = 0
      let urgent = 0
      let suggested = 0

      response.data.forEach((item) => {
        if (!item.repair.payload.repair.isHidden) {
          switch (item.repair.payload.repair.priority) {
            case this.priorityLavel.low:
              good++
              break
            case this.priorityLavel.suggested:
              suggested++
              break
            case this.priorityLavel.urgent:
              urgent++
              break
          }
        }
      })
      return {
        good,
        urgent,
        suggested,
      }
    })
  }

  reduceRepairEstimatesData(repairsEstimatesData) {
    let totalApproved = 0.0
    repairsEstimatesData.map(
      (item) =>
        (totalApproved += item.reduce(
          (acc, subItem) => acc + subItem.totalPrice,
          0.0
        ))
    )
    return round(totalApproved, 2)
  }

  async countTotalRepairsApproved(repairs) {
    try {
      const estimatePromises = []

      for (let i = 0; i < repairs.length; i++) {
        if (repairs[i].isApproved) {
          estimatePromises.push(
            this.inspectionItemService.getSubItems(repairs[i].id)
          )
        }
      }

      let repairsEstimatesData = await Promise.all(estimatePromises)
      return this.reduceRepairEstimatesData(repairsEstimatesData)
    } catch (error) {
      console.error(error)
    }
  }

  getTotalRepairsApproved(roNumberEncode) {
    return new Promise((resolve) => {
      this.inspectionItemService
        .getAllRepairs(roNumberEncode)
        .then(({ data: repairs }) => {
          const estimatePromises = []

          for (let i = 0; i < repairs.length; i++) {
            if (repairs[i].repair.payload.repair.isApproved) {
              estimatePromises.push(
                this.inspectionItemService.getSubItems(
                  repairs[i].repair.payload.repair.id
                )
              )
            }
          }

          return Promise.all(estimatePromises)
            .then((repairsEstimatesData) => {
              let totalApproved = 0.0
              for (let i = 0; i < repairsEstimatesData.length; i++) {
                totalApproved += repairsEstimatesData[i].reduce(
                  (acc, subItem) => {
                    return acc + subItem.totalPrice
                  },
                  0.0
                )
              }
              resolve(toFixed(totalApproved, 2))
            })
            .catch(() => resolve(0.0))
        })
    })
  }

  getTeamMember(repairShopId, inChargeUserId) {
    const teamMember = {
      mobileNumber: '',
      landlineNumber: '',
    }
    if (!inChargeUserId) {
      return teamMember
    }
    return this.get('/users/' + repairShopId)
      .then(({ data }) => {
        const chageUser = data.find((user) => user.userId === inChargeUserId)

        teamMember.mobileNumber = chageUser.payload.user.mobileNumber || ''
        teamMember.landlineNumber = chageUser.payload.user.landlineNumber || ''

        return teamMember
      })
      .catch(() => {
        return teamMember
      })
  }

  approveInspectionItem(token, repairOrderId, approvedItems) {
    const payload = {
      repairOrderId,
      approvedItems,
    }

    return this.post('/approveitems', payload, { params: { token } })
      .then((response) => {
        return response.data.totalApproved
      })
      .catch(() => {
        throw Error('An Error has occurred to approve inspection items')
      })
  }
}

export { RepairOrderService }
