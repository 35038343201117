import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { BasicLoader } from '../components/BasicLoader'

import { RepairOrderContainer } from '../containers/repairOrder.container'
import { RepairShopContainer } from '../containers/repairShop.container'
import { AdditionalNoteContainer } from '../containers/additionalNote.container'
import { withConnect } from './withConnect'
import { withQueryString } from './withQueryString'

import { getDisplayNameHOC } from '../utils/getDisplayNameHOC'

function withMainData(
  WrappedComponent,
  configs = { updateStateWithData: false, containers: [] }
) {
  class MainData extends Component {
    componentDidMount() {
      const [
        RepairOrderContainer,
        RepairShopContainer,
        AdditionalNoteContainer,
      ] = this.props.containers
      if (!this.props.qsValues.roNumberEncode || !this.props.qsValues.iv) {
        navigate('/digital-repair-order/not-found', { replace: true })
        return
      }

      function setRepairShopData(repairOrderData) {
        localStorage.setItem(
          'repairShopLanguageCode',
          repairOrderData.repairShop.languageCode
        )
        localStorage.setItem(
          'repairShopTaxPercentage',
          repairOrderData.repairShop.taxPercentage || 0
        )
        return RepairShopContainer.setData(repairOrderData.repairShop)
      }

      function manageRepairShopData(repairShopData, props) {
        props.i18n.changeLanguage(repairShopData.languageCode)
        RepairOrderContainer.countTotalRepairsApproved()
      }

      if (
        configs.updateStateWithData ||
        !RepairOrderContainer.state.loadedData
      ) {
        RepairOrderContainer.getData(this.props.qsValues.roNumberEncode)
          .then((repairOrderData) => setRepairShopData(repairOrderData))
          .then((repairShopData) =>
            manageRepairShopData(repairShopData, this.props)
          )

        AdditionalNoteContainer.getData(this.props.qsValues.roNumberEncode)
      }
    }

    render() {
      const [RepairOrderContainer, RepairShopContainer] = this.props.containers

      if (
        RepairOrderContainer.state.loadingData ||
        RepairShopContainer.state.loadingData
      ) {
        return (
          <div className="common--default-background">
            <BasicLoader sizeLoader="big" />
          </div>
        )
      }
      return (
        <WrappedComponent
          {...this.props}
          repairOrderData={RepairOrderContainer.state}
          repairShopData={RepairShopContainer.state}
          containers={this.props.containers}
        />
      )
    }
  }

  MainData.displayName = `WithMainData(${getDisplayNameHOC(WrappedComponent)})`

  return withConnect([
    RepairOrderContainer,
    RepairShopContainer,
    AdditionalNoteContainer,
    ...configs.containers,
  ])(withQueryString(MainData))
}

export { withMainData }
