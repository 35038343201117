class SelectData {
  /* TODO - move the function in ./src/utils/getServiceAdvisorPhone.js to here.  */

  static selectLogo(showVehicleMakeLogo, makeLogoUrl, consumerFormLogo) {
    if (showVehicleMakeLogo && makeLogoUrl) {
      return makeLogoUrl
    }

    return consumerFormLogo
  }
}

export default SelectData
