import arrowImg from '../assets/images/overlay/arrow.png'
import circleImg from '../assets/images/overlay/circle.png'

function showOverlay(imageRef, containerRef, originalWidth, overlays) {
  const markers = []
  const imageWidth = imageRef.width
  const imageHeight = imageRef.height
  const containerHeight = containerRef.clientHeight
  const verticalCorrection = (containerHeight - imageHeight) / 2

  const proportion = imageWidth / originalWidth
  const showNumber = overlays.length > 1

  for (let j = 0; j < overlays.length; j++) {
    const item = overlays[j]
    markers.push(
      createOverlayControl(
        item.x,
        item.y,
        item.size,
        proportion,
        verticalCorrection,
        item.type,
        j + 1,
        showNumber,
        imageWidth,
        imageHeight
      )
    )
  }

  return markers
}

function createOverlayControl(
  x,
  y,
  size,
  proportion,
  verticalCorrection,
  type,
  number,
  showNumber,
  imageWidth,
  imageHeight
) {
  const markerPosition = {}
  x = Math.round(x * proportion)
  y = Math.round(y * proportion + verticalCorrection)
  size = Math.round(size * proportion)
  let numberX = 0
  let numberY = 0
  const halfSize = Math.round(size / 2)

  if (type === 'Arrow') {
    x -= size
    numberX = x
    numberY = y + size
    markerPosition.markerSrc = arrowImg
  } else if (type === 'Circle') {
    numberX = x - Math.round(7 * proportion)
    numberY = y + halfSize
    x -= halfSize
    y -= halfSize
    markerPosition.markerSrc = circleImg
  }

  const maxImageDimension = imageWidth >= imageHeight ? imageWidth : imageHeight

  if (maxImageDimension < 150) {
    showNumber = false
  }

  markerPosition.showNumber = showNumber
  markerPosition.number = number
  markerPosition.numberY = numberY
  markerPosition.numberX = numberX
  markerPosition.imageY = y
  markerPosition.imageX = x
  markerPosition.size = size

  return markerPosition
}

export { showOverlay }
