import { BaseService } from './Base.service'
import { calcTaxes, toFixed } from '../utils/calc'
import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_PHOTO,
} from '../constant/mediaType'
import {
  LOW_PRIORITY,
  URGENT_PRIORITY,
  SUGGESTED_PRIORITY,
} from '../constant/priorityLevel'
const { round } = require('js-round')

import 'aws-sdk/dist/aws-sdk'

const AWS = window.AWS

const AWS_EXPIRES_CONST = 45 * 60

AWS.config.update({
  accessKeyId: process.env.S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.S3_SECRET_ACCESS_KEY,
  signatureVersion: 'v4',
  region: 'ca-central-1',
})

const APPROVED_REPAIR = 'approved'
const ADD_REPAIR = 'add'

class InspectionItemService extends BaseService {
  constructor(repairShopContainer) {
    super()
    this.lengBR = 'br'
    this.repairShopContainer = repairShopContainer
    this.s3 = new AWS.S3()
    this.formattedRepairs = []
  }

  async getRepairs(roNumberEncode) {
    let data = {}

    try {
      const repairs = await this.getAllRepairs(roNumberEncode)
      data = await this.formatData(repairs.data)
    } catch (error) {}

    return data
  }

  formatRepairAndPushitToArray(item) {
    let hasRepairShopTax = this.repairShopContainer.state.taxPercentage != 0
    let taxPercentage = hasRepairShopTax
      ? this.repairShopContainer.state.taxPercentage
      : +localStorage.getItem('repairShopTaxPercentage') || 0
    let current = item.system || {
      isHiddenForConsumer: false,
      masterItem: { isHiddenForConsumer: false },
    }
    if (
      current.isHiddenForConsumer === false &&
      current.masterItem.isHiddenForConsumer === false &&
      !item.isHidden
    ) {
      const repair = {
        id: item.id,
        name: item.masterItem.name,
        isApproved: item.isApproved,
        isHidden: item.isHidden,
        masterItemId: item.masterItem.masterItemId,
        approvalStatus: item.isApproved ? APPROVED_REPAIR : ADD_REPAIR,
        priority: item.priority,
        comments: item.comments,
        media: null,
        thumbnail: null,
        subItems: [],
      }
      item.estimates.map((est) =>
        repair.subItems.push({
          id: est.id,
          name: est.name,
          totalPrice: this.calcuateTotal(est, taxPercentage),
          availability: est.availability,
          inventoryComments: est.inventoryComments,
          quantity: est.quantity,
          isSubitem: est.isSubitem,
        })
      )
      repair.total = this.getInspectionItemTotal(repair.subItems)
      let formattedMediaFiles = this.formatMediaFile(item.mediaFiles)
      let formattedDiagrams = this.formatDiagrams(item.masterItem.diagrams)
      repair.media = [...formattedMediaFiles, ...formattedDiagrams]
      repair.thumbnail = this.getThumbnail(repair.media)
      this.formattedRepairs.push(repair)
    }
  }

  formatRepairs(repairs) {
    repairs.map((item) => this.formatRepairAndPushitToArray(item))

    const urgent = []
    const low = []
    const suggested = []

    this.formattedRepairs.map((item) => {
      switch (item.priority) {
        case LOW_PRIORITY:
          low.push(item)
          break
        case SUGGESTED_PRIORITY:
          suggested.push(item)
          break
        case URGENT_PRIORITY:
          urgent.push(item)
          break
        default:
          break
      }
    })
    return { urgent, low, suggested }
  }

  formatMediaFile(mediaFiles) {
    const media = []

    try {
      const data = mediaFiles
      data.map((item) => {
        let fileURL = ''
        let thumbnailURL = ''

        if (!item.showToConsumer) {
          return
        }

        if (item.s3BucketName && item.s3FileKey) {
          fileURL = this.s3.getSignedUrl('getObject', {
            Bucket: item.s3BucketName,
            Key: item.s3FileKey,
            Expires: AWS_EXPIRES_CONST,
          })
        }

        if (item.s3BucketName && item.s3FileKeyThumbnail) {
          thumbnailURL = this.s3.getSignedUrl('getObject', {
            Bucket: item.s3BucketName,
            Key: item.s3FileKeyThumbnail,
            Expires: AWS_EXPIRES_CONST,
          })
        }

        media.push({
          id: item.id,
          originalHeight: item.originalHeight,
          originalWidth: item.originalWidth,
          showToConsumer: item.showToConsumer,
          type: item.type,
          filePath: fileURL,
          thumbnailFilePath: thumbnailURL,
          isDiagram: false,
        })
      })
    } catch (err) {
      console.error(err)
    }

    return media
  }

  formatDiagrams(diagrams) {
    let diagramList = []
    let fileURL = ''
    let thumbnailURL = ''

    try {
      const data = diagrams
      const hasRepairShopData =
        this.repairShopContainer.state.languageCode != ''
      const lengSplit = hasRepairShopData
        ? this.repairShopContainer.state.languageCode.split('-')
        : localStorage.getItem('repairShopLanguageCode').split('-')
      const diagramLeng =
        lengSplit[1].toLowerCase() === this.lengBR
          ? lengSplit[1].toLowerCase()
          : lengSplit[0]

      diagramList = data.map((item) => {
        if (item.s3FileKey && item.s3BucketName) {
          fileURL = this.s3.getSignedUrl('getObject', {
            Bucket: item.s3BucketName,
            Key: item.s3FileKey.replace('{lang}', diagramLeng),
            Expires: AWS_EXPIRES_CONST,
          })
        }

        if (item.s3BucketName && item.s3FileKeyThumbnail) {
          thumbnailURL = this.s3.getSignedUrl('getObject', {
            Bucket: item.s3BucketName,
            Key: item.s3FileKeyThumbnail.replace('{lang}', diagramLeng),
            Expires: AWS_EXPIRES_CONST,
          })
        }

        return {
          showToConsumer: true,
          type: MEDIA_TYPE_PHOTO,
          filePath: fileURL,
          thumbnailFilePath: thumbnailURL,
          isDiagram: true,
        }
      })
    } catch (err) {
      console.error(err)
    }

    return diagramList
  }

  formatData(repairs) {
    return new Promise((resolve, reject) => {
      const promiseArray = []
      for (let i = 0; i < repairs.length; i++) {
        const item = repairs[i]
        const roId = repairs[i].repairOrderId

        if (item.isHidden) {
          continue
        }
        promiseArray.push(
          new Promise((resolve, reject) => {
            const repair = {
              id: item.id,
              name: item.masterItem.name,
              isApproved: item.isApproved,
              isHidden: item.isHidden,
              masterItemId: item.masterItem.masterItemId,
              approvalStatus: item.isApproved ? 'approved' : 'add',
              priority: item.priority,
            }

            this.getComments(item.id)
              .then((comments) => {
                repair.comments = comments
                return this.getSubItems(item.id)
              })
              .then((subItems) => {
                repair.subItems = subItems
                repair.total = this.getInspectionItemTotal(subItems)
                return this.getMedia(item.id, item.masterItem.masterItemId)
              })
              .then((mediaData) => {
                repair.media = mediaData.media
                repair.thumbnail = mediaData.thumbnail
                return this.getOverlays(roId, mediaData.media)
              })
              .then((mediaWithOverlays) => {
                repair.media = mediaWithOverlays
                resolve(repair)
              })
              .catch(reject)
          })
        )
      }

      Promise.all(promiseArray)
        .then((items) => {
          const urgent = []
          const low = []
          const suggested = []

          for (let i = 0; i < items.length; i++) {
            const item = items[i]
            switch (item.priority) {
              case LOW_PRIORITY:
                low.push(item)
                break
              case SUGGESTED_PRIORITY:
                suggested.push(item)
                break
              case URGENT_PRIORITY:
                urgent.push(item)
                break
            }
          }
          resolve({ urgent, low, suggested })
        })
        .catch(reject)
    })
  }

  getAllRepairs(roNumberEncode) {
    return this.get('repairs/' + roNumberEncode)
  }

  getComments(repairID) {
    return new Promise((resolve, reject) => {
      this.get('comments/' + repairID)
        .then((response) => {
          const data = response.data

          const comments = data.map((item) => {
            return item.comment.payload.comment
          })

          resolve(comments)
        })
        .catch(() => {
          resolve([])
        })
    })
  }

  getSubItems(repairID) {
    let hasRepairShopTax = this.repairShopContainer.state.taxPercentage != 0
    let taxPercentage = hasRepairShopTax
      ? this.repairShopContainer.state.taxPercentage
      : +localStorage.getItem('repairShopTaxPercentage') || 0
    return new Promise((resolve) => {
      this.get('estimates/' + repairID)
        .then(({ data }) => {
          const subItems = data.map((item) => {
            return {
              id: item.estimate.payload.estimate.id,
              name: item.estimate.payload.estimate.name,
              totalPrice: this.calcuateTotal(
                item.estimate.payload.estimate,
                taxPercentage
              ),
            }
          })

          resolve(subItems)
        })
        .catch(() => resolve([]))
    })
  }

  calcuateTotal(estimate, taxPercentage) {
    const hours = estimate.hours || 0;
    const hourPrice = estimate.hourPrice || 0
    const unitPrice = estimate.unitPrice || 0
    const quantity = estimate.quantity || 1

    let preTotal = hours * hourPrice + unitPrice * quantity
    preTotal += calcTaxes(taxPercentage, preTotal)
    let total = round(preTotal, 2)

    return total
  }

  getMedia(repairID, masterItemId) {
    const media = {
      media: [],
      thumbnail: {},
    }

    return Promise.all([
      this.getMediaFile(repairID),
      this.getDiagrams(masterItemId),
    ]).then((mediaData) => {
      media.media = [...mediaData[0], ...mediaData[1]]
      media.thumbnail = this.getThumbnail(media.media)
      return media
    })
  }

  async getMediaFile(repairID) {
    const media = []

    try {
      const data = (await this.get('media-files/' + repairID)).data

      data.forEach((item) => {
        let fileURL = ''
        let thumbnailURL = ''

        if (!item.media.payload.media.showToConsumer) {
          return
        }

        if (
          item.media.payload.media.s3BucketName &&
          item.media.payload.media.s3FileKey
        ) {
          fileURL = this.s3.getSignedUrl('getObject', {
            Bucket: item.media.payload.media.s3BucketName,
            Key: item.media.payload.media.s3FileKey,
            Expires: 45 * 60,
          })
        }
        if (
          item.media.payload.media.s3BucketName &&
          item.media.payload.media.s3FileKeyThumbnail
        ) {
          thumbnailURL = this.s3.getSignedUrl('getObject', {
            Bucket: item.media.payload.media.s3BucketName,
            Key: item.media.payload.media.s3FileKeyThumbnail,
            Expires: 45 * 60,
          })
        }

        media.push({
          id: item.media.payload.media.id,
          originalHeight: item.media.payload.media.originalHeight,
          originalWidth: item.media.payload.media.originalWidth,
          showToConsumer: item.media.payload.media.showToConsumer,
          type: item.media.payload.media.type,
          filePath: fileURL,
          thumbnailFilePath: thumbnailURL,
          isDiagram: false,
        })
      })
    } catch (err) {}

    return media
  }

  async getDiagrams(masterItemId) {
    let diagrams = []
    let fileURL = ''
    let thumbnailURL = ''

    try {
      const data = (await this.get('diagrams/' + masterItemId)).data
      const lengSplit = this.repairShopContainer.state.languageCode.split('-')
      const diagramLeng =
        lengSplit[1].toLowerCase() === this.lengBR
          ? lengSplit[1].toLowerCase()
          : lengSplit[0]

      diagrams = data.map((item) => {
        if (
          item.payload.diagram.s3BucketName &&
          item.payload.diagram.s3FileKey
        ) {
          fileURL = this.s3.getSignedUrl('getObject', {
            Bucket: item.payload.diagram.s3BucketName,
            Key: item.payload.diagram.s3FileKey.replace('{lang}', diagramLeng),
            Expires: 45 * 60,
          })
        }

        if (
          item.payload.diagram.s3BucketName &&
          item.payload.diagram.s3FileKeyThumbnail
        ) {
          thumbnailURL = this.s3.getSignedUrl('getObject', {
            Bucket: item.payload.diagram.s3BucketName,
            Key: item.payload.diagram.s3FileKeyThumbnail.replace(
              '{lang}',
              diagramLeng
            ),
            Expires: 45 * 60,
          })
        }

        return {
          showToConsumer: true,
          type: MEDIA_TYPE_PHOTO,
          filePath: fileURL,
          thumbnailFilePath: thumbnailURL,
          isDiagram: true,
        }
      })
    } catch (err) {}

    return diagrams
  }

  async getOverlays(repairOrderId, media) {
    let mediaWithOverlays = [...media]

    try {
      const overlaysData = (await this.get('overlays/' + repairOrderId)).data
      mediaWithOverlays = media.map((item) => {
        const overlays = []
        for (let i = 0; i < overlaysData.length; i++) {
          if (item.id === overlaysData[i].payload.media.repairImageId) {
            overlays.push(overlaysData[i].payload.media)
          }
        }
        return { ...item, overlays }
      })
    } catch (err) {}

    return mediaWithOverlays
  }

  getInspectionItemTotal(subItems) {
    const total = subItems.reduce((acc, cur) => acc + (cur.totalPrice || 0), 0)
    return round(total, 2)
  }

  getThumbnail(media) {
    const videoCounter = { counter: 0, resource: '' }
    const imageCounter = { counter: 0, resource: '' }
    const audioCounter = { counter: 0, resource: '' }
    const diagramPaths = []

    media.forEach((item) => {
      switch (item.type) {
        case MEDIA_TYPE_VIDEO:
          videoCounter.counter++
          videoCounter.resource = item.thumbnailFilePath
          break
        case MEDIA_TYPE_PHOTO:
          imageCounter.counter++
          if (item.isDiagram) {
            diagramPaths.push(item.thumbnailFilePath)
          } else {
            imageCounter.resource = item.thumbnailFilePath
          }

          break
        case MEDIA_TYPE_AUDIO:
          audioCounter.counter++
          audioCounter.resource = item.thumbnailFilePath
          break
      }
    })

    if (imageCounter.resource === '' && imageCounter.counter) {
      imageCounter.resource = diagramPaths[0]
    }
    return this.selectThumbnail(videoCounter, imageCounter, audioCounter)
  }

  selectThumbnail(videoCounter, imageCounter, audioCounter) {
    const thumbnail = { iconType: '', resource: '' }

    if (
      !videoCounter.counter &&
      !imageCounter.counter &&
      !audioCounter.counter
    ) {
      return thumbnail
    }

    if (
      (!videoCounter.counter && !audioCounter.counter) ||
      (!videoCounter.counter && imageCounter.counter && audioCounter.counter)
    ) {
      thumbnail.iconType = MEDIA_TYPE_PHOTO
      thumbnail.resource = imageCounter.resource
      return thumbnail
    }

    if (!videoCounter.counter && !imageCounter.counter) {
      thumbnail.iconType = MEDIA_TYPE_AUDIO
      thumbnail.resource = ''
      return thumbnail
    }

    thumbnail.iconType = MEDIA_TYPE_VIDEO
    thumbnail.resource = videoCounter.resource
    return thumbnail
  }
}

export { InspectionItemService }
