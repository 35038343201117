import React from 'react'
import PropTypes from 'prop-types'

import { IconButtonLBStyle, LabelButtonStyle } from './style'
import { LabelPrimary } from '../../../../components/Labels'

function LinkDownload({ children, dlResource, newTab = false }) {
  return dlResource ? (
    <a
      href={dlResource.src}
      download={dlResource.name}
      target={newTab ? '_blank' : '_self'}
    >
      {children}
    </a>
  ) : (
    children
  )
}
function LabelButton({ dlResource, label, icon: Icon, newTab = false }) {
  return (
    <LabelButtonStyle
      item
      xs={5}
      sm={5}
      md={5}
      lg={5}
      container
      wrap="nowrap"
      alignItems="center"
      justify="flex-end"
    >
      <LinkDownload dlResource={dlResource} newTab={newTab}>
        <IconButtonLBStyle variant="rounded">
          <Icon />
        </IconButtonLBStyle>
        <LabelPrimary size="s1">{label}</LabelPrimary>
      </LinkDownload>
    </LabelButtonStyle>
  )
}

LabelButton.propTypes = {
  dlResource: PropTypes.shape({
    src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
}

export { LabelButton }
