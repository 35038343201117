import styled from 'styled-components'
import { SwipeableDrawer } from '@material-ui/core'

import { breakpoints } from '../../styles/helpers/mediaQuery'
import backgroundSquareTwo from '../../assets/images/background-square-two.png'

export const SideMenuStyle = styled(SwipeableDrawer)`
  .MuiDrawer-paperAnchorLeft {
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    padding: 50px 20px 0px 20px;
    width: 294px;

    & > .sideMenu--header {
      margin: 32px 0 24px 0;
    }

    & > .sideMenu--list {
      margin-top: 24px;

      & .MuiSvgIcon-fontSizeSmall {
        font-size: 16px;
      }

      & .MuiListItemIcon-root {
        color: #dadada;
        margin-right: 4px;
        min-width: initial;
      }
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    .MuiDrawer-paperAnchorLeft {
      background: #ffffff url(${backgroundSquareTwo}) no-repeat 9% 97%;
      padding: 75px 20px 0px 20px;
      width: 300px;

      & > .sideMenu--header {
        margin: 24px 0 16px 0;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .MuiDrawer-paperAnchorLeft {
      padding: 75px 28px 0px 28px;
      width: 416px;

      & > .sideMenu--header {
        margin: 32px 0 20px 0;
        & > p {
          font-size: 24px;
        }
      }

      & .sideMenu--list--itemLabel {
        font-size: 20px;
      }

      & > .sideMenu--list {
        &--itemLabel {
          font-size: 20px;
        }

        & .MuiSvgIcon-fontSizeSmall {
          font-size: 20px;
        }

        & .MuiListItemIcon-root {
          margin-right: 12px;
        }
      }
    }
  }
`
