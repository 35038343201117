import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@material-ui/icons'
import { LabelPrimary } from '../../../../components/Labels'

import { InspectionItem } from '../InspectionItem'

import { withConnect } from '../../../../HOC/withConnect'
import { RepairShopContainer } from '../../../../containers/repairShop.container'

import {
  InspectionItemsContainerStyle,
  AcordionTitleSectionStyled,
  AccordionStyle,
  AccordionSummaryStyle,
  AccordionDetailsStyle,
} from './styles'

import { scrollToRef } from '../../../../utils/scroll'

function RenderGrid({ children, title }) {
  return (
    <>
      <LabelPrimary>{title}</LabelPrimary>
      <InspectionItemsContainerStyle>{children}</InspectionItemsContainerStyle>
    </>
  )
}

function RenderAcordion({ children, title, itemsTotal, priority, open }) {
  const [isExpanded, setExpandedState] = useState(false)
  const handleChange = () => setExpandedState(!isExpanded)

  return (
    <AccordionStyle
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={open}
    >
      <AccordionSummaryStyle>
        <AcordionTitleSectionStyled priority={priority}>
          <p className="accordionTitleSection--items-amount">{itemsTotal}</p>
          <p className="accordionTitleSection--title">{title}</p>
          <div className="accordionTitleSection--expanded-icon">
            {isExpanded ? (
              <ArrowDropUpIcon fontSize="small" />
            ) : (
              <ArrowDropDownIcon fontSize="small" />
            )}
          </div>
        </AcordionTitleSectionStyled>
      </AccordionSummaryStyle>
      <AccordionDetailsStyle>
        <InspectionItemsContainerStyle container wrap="wrap">
          {children}
        </InspectionItemsContainerStyle>
      </AccordionDetailsStyle>
    </AccordionStyle>
  )
}

function InspectionItemsListContainer({
  children,
  accordionMode,
  title,
  itemsTotal,
  priority,
  open,
}) {
  return accordionMode ? (
    <RenderAcordion
      children={children}
      title={title}
      itemsTotal={itemsTotal}
      priority={priority}
      open={open}
    />
  ) : (
    <RenderGrid children={children} title={title} priority={priority} />
  )
}

function InspectionItemsListStateLess({
  accordionMode = false,
  containers,
  inspectionItems,
  title,
  onOpenEvidenceModalClick,
  onAddInspectionItemClick,
  showEstimate,
  priority = 'urgent',
  open = false,
}) {
  const scrollRef = useRef(null)

  useEffect(() => {
    if (open) {
      scrollToRef(scrollRef)
    }
  }, [])

  return !inspectionItems.length ? null : (
    <div ref={scrollRef}>
      <InspectionItemsListContainer
        accordionMode={accordionMode}
        title={title}
        itemsTotal={inspectionItems.length}
        priority={priority}
        open={open}
      >
        {inspectionItems.map((item, index) => {
          return (
            <InspectionItem
              comments={item.comments}
              key={`ITL-${index}`}
              name={item.name}
              onOpenEvidenceClick={() => onOpenEvidenceModalClick(item)}
              onAddInspectionItemClick={(status) =>
                onAddInspectionItemClick(
                  item.id,
                  item.masterItemId,
                  item.name,
                  item.total,
                  status
                )
              }
              showEstimate={showEstimate}
              showEstimateSection={priority !== 'good'}
              subItems={item.subItems}
              approvalStatus={item.approvalStatus}
              thumbnail={item.thumbnail}
              total={item.total}
            />
          )
        })}
      </InspectionItemsListContainer>
    </div>
  )
}

InspectionItemsListStateLess.propTypes = {
  accordionMode: PropTypes.bool,
  inspectionItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      thumbnail: PropTypes.string,
      total: PropTypes.number,
      comments: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  title: PropTypes.string,
  onOpenEvidenceModalClick: PropTypes.func,
  onAddInspectionItemClick: PropTypes.func,
  open: PropTypes.bool,
  showEstimate: PropTypes.bool,
  priority: PropTypes.string,
}

const InspectionItemsList = withConnect([RepairShopContainer])(
  InspectionItemsListStateLess
)
export { InspectionItemsList }
