import { BaseService } from './Base.service.js'

class StatusKarloService extends BaseService {
  constructor() {
    //super("","https://backend.karlo.io/api/webService/")
    super('', 'https://backend-production.karlo.io/api/webService/')
    this.userLoginResponse = []
  }

  async cmPaymentStatus(organizationID, orderID, token) {
    let data = {}
    this.setToken(token)
    try {
      let payload = {
        organizationID: organizationID,
        orderID: orderID,
      }
      const cmPaymentStatusRequest = await this.post(
        'cm/paymentStatus',
        payload
      )
      data = cmPaymentStatusRequest.data
    } catch (error) {
      throw new Error('Resource was not founded')
    }
    return data
  }

  async userLogin(user, password) {
    let data = {}
    this.setToken('')
    try {
      const userLoginRequest = await this.post('user/login', {
        email: user,
        password: password,
      })
      data = userLoginRequest.data
    } catch (error) {
      throw new Error('Resource was not founded')
    }
    return data
  }
}

export { StatusKarloService }
