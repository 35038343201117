import { BaseService } from './Base.service'

class AdditionalNoteService extends BaseService {
  getAdditionalNotes(roNumberEncode) {
    return this.get('additional-notes/' + roNumberEncode)
      .then((response) => {
        return response.data.map(
          (note) => note.additionalNote.payload.additionalNote
        )
      })
      .catch((error) => {
        if (error.response.status == 404) {
          return []
        }
        console.error('We have had a error')
      })
  }
}

export { AdditionalNoteService }
