import { Card } from '@material-ui/core'
import styled from 'styled-components'
import { breakpoints } from '../../../../styles/helpers/mediaQuery'

export const NotesHeader = styled.h2`
  font-weight: bold;
  font-size: 1.2em;
`

export const NotesRoot = styled(Card)`
  padding: 15px !important;
  margin: 0px 16px 16px 16px !important;
  box-shadow: none;
  border-radius: 15px;

  @media (max-width: ${breakpoints.sm}px) {
    & {
      margin: 0 0 15px 0 !important;
      padding: 0 !important;
      border-radius: 0;
    }
  }
`
