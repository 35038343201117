import styled from 'styled-components'
import { AppBar, Toolbar, Grid } from '@material-ui/core'

import { mediaQuery } from '../../styles/helpers/mediaQuery'

export const NavBarStyle = styled(AppBar)`
  background-color: #0048c0;
  height: 50px;
  z-index: 100000;

  ${mediaQuery('sm')`
    height: 60px;
  `}

  ${mediaQuery('md')`
    height: 72px;
  `}
`

export const NavBarToolbarStyle = styled(Toolbar)`
  min-height: 50px;
  padding: 0px 18px;

  ${mediaQuery('sm')`
    padding: 0 60px 0 104px;
  `}

  ${mediaQuery('md')`
    padding-right: 72px;
  `}
`
export const NavBarGridDro = styled(Grid)`
  #header--navbarbutton {
    height: 24px;
    width: 24px;
  } 
  
  #header--previousButton {
    color: #ffffff;
  } 

  #header--workshop--nameArea{
    
    > #workshop--nameLabel{
      color: #ffffff;
      padding-right: 20px;
      font-size: 16px;
      text-align: right;

    }

  }
  
  ${mediaQuery('sm')`
      #header--navbarbutton{
        height: 36px;
        width: 36px;
        & .MuiSvgIcon-root{
          font-size: 36px;
        }
      }

      #header--previousButton {
        height: 24px;
        width: 24px;
        & .MuiSvgIcon-root{
          font-size: 24px;
        }
      } 

      #header--workshop--nameArea{
        margin-right: -10%;
        > #workshop--nameLabel{
          font-size: 20px;
        }
      }
  `}

  ${mediaQuery('md')`
      #header--navbarbutton {
        height: 40px;
        width: 40px;
        & .MuiSvgIcon-root{
          font-size: 40px;
        }
        
      } 

      #header--workshop--nameArea{
        margin-right: -15%;
        > #workshop--nameLabel{
          font-size: 24px;
        }
      }
  `}

  ${mediaQuery('lg')`
    #header--workshop--nameArea{
      margin-right: -20%;
      > #workshop--nameLabel{
        font-size: 20px;
      }
    }
  `}
`
