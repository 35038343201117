import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { StatusButton } from '../StatusButton'
import { useTranslation } from 'react-i18next'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons'

import { ThumbnailImage } from '../../../../components/ThumbnailImage'

import { InspectionItemStyle } from './styles'

import { withConnect } from '../../../../HOC/withConnect'
import { withQueryString } from '../../../../HOC/withQueryString'
import { RepairShopContainer } from '../../../../containers/repairShop.container'
import str from '../../../../utils/str'

function InspectionItemStateLess({
  approvalStatus,
  comments,
  containers,
  name,
  onOpenEvidenceClick,
  onAddInspectionItemClick,
  showEstimate,
  showEstimateSection = true,
  subItems,
  total,
  thumbnail,
  qsValues,
}) {
  const [RepairShopContainer] = containers
  const { t } = useTranslation()
  const [expandMore, setExpandMore] = useState(false)
  const setExpandMoreState = () => setExpandMore(!expandMore)
  let commentsStr = comments
    .reduce((accu, curre) => accu + ', ' + curre.text, '')
    .slice(1)
  const isIV = qsValues.iv.toLowerCase() === 'true'
  const notAllowAdd =
    isIV ||
    !RepairShopContainer.state.customerApprovalEmails ||
    (RepairShopContainer.state.approveOnlyEstimated && total === 0)

  if (commentsStr.length > 150) {
    commentsStr = commentsStr.slice(0, 150) + '...'
  }
  const _n = RepairShopContainer.state.removeDecimals ? 0 : 2
  const itemEstimate = subItems.find((x) => !x.subItem)
  return (
    <InspectionItemStyle
      item
      xs={12}
      sm={5}
      md={3}
      style={expandMore ? { maxHeight: 'initial' } : {}}
    >
      <div className="inspectionItem--thumbnailSection">
        <ThumbnailImage
          iconType={thumbnail.iconType}
          src={thumbnail.resource}
          onClick={onOpenEvidenceClick}
        />
      </div>
      <div
        className="inspectionItem--footer"
        style={{ justifyContent: !showEstimate ? 'flex-end' : '' }}
      >
        {showEstimateSection ? (
          <>
            {showEstimate && (
              <p className="inspectionItem--costLabel">
                {str.format(
                  RepairShopContainer.state.currency,
                  str.numberFormat(
                    total,
                    _n,
                    3,
                    RepairShopContainer.state.groupSeparator,
                    RepairShopContainer.state.decimalSeparator
                  )
                )}
              </p>
            )}
            <StatusButton
              status={approvalStatus}
              disabled={!showEstimate || notAllowAdd}
              onItemStatusClick={onAddInspectionItemClick}
            />
          </>
        ) : null}
      </div>
      <div className="inspectionItem--descriptionSection">
        <p>{name}</p>
        <p>
          {commentsStr}
          <div className="inspectionItem--availableSection">
            <b style={{ fontSize: '13px' }}>
              {subItems.length &&
              itemEstimate &&
              itemEstimate.availability != null &&
              itemEstimate.quantity != null ? (
                <span>
                  {commentsStr.length > 0 ? <br /> : null}
                  {t('inspectionItems.inspectionItem.available')}:{' '}
                  {itemEstimate.availability < itemEstimate.quantity
                    ? t('common.no')
                    : t('common.yes')}
                </span>
              ) : subItems.length &&
                itemEstimate &&
                itemEstimate.inventoryComments ? (
                t('inspectionItems.inspectionItem.inventoryComments') + ': '
              ) : null}
              <br />
              {subItems.length &&
              !itemEstimate.isSubitem &&
              itemEstimate.inventoryComments
                ? itemEstimate.inventoryComments
                : null}
            </b>
          </div>
        </p>
      </div>
      <div className="inspectionItem--expandMoreIconSection">
        {showEstimate && subItems.length > 1 ? (
          <IconButton aria-label="expand more" onClick={setExpandMoreState}>
            {expandMore ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ExpandLessIcon fontSize="small" />
            )}
          </IconButton>
        ) : null}
      </div>
      <div
        className="inspectionItem--subItemSection"
        style={{ display: expandMore ? 'block' : 'none' }}
      >
        {subItems &&
          subItems
            .filter((x) => x.isSubitem)
            .map((subItem, index) => (
              <p key={`II-SI-${index}`}>
                <span>{subItem.name}</span>
                <span>
                  {str.format(
                    RepairShopContainer.state.currency,
                    str.numberFormat(
                      subItem.totalPrice,
                      _n,
                      3,
                      RepairShopContainer.state.groupSeparator,
                      RepairShopContainer.state.decimalSeparator
                    )
                  )}
                </span>
                <div>
                  {subItem.isSubitem &&
                  subItem.availability != null &&
                  subItem.quantity != null ? (
                    <small>
                      {t('inspectionItems.inspectionItem.available')}:{' '}
                      {subItem.availability < subItem.quantity
                        ? t('common.no')
                        : t('common.yes')}
                    </small>
                  ) : null}
                </div>
                <div>
                  {subItem.isSubitem && subItem.inventoryComments ? (
                    <small style={{ textTransform: 'initial' }}>
                      {subItem.availability == null || subItem.quantity == null
                        ? t(
                            'inspectionItems.inspectionItem.inventoryComments'
                          ) + ': '
                        : ''}
                      {subItem.inventoryComments}
                    </small>
                  ) : null}
                </div>
              </p>
            ))}
      </div>
    </InspectionItemStyle>
  )
}

InspectionItemStateLess.propTypes = {
  approvalStatus: PropTypes.string,
  comments: PropTypes.arrayOf(PropTypes.string),
  containers: PropTypes.array,
  name: PropTypes.string,
  onOpenEvidenceClick: PropTypes.func,
  onAddInspectionItemClick: PropTypes.func,
  showEstimate: PropTypes.bool,
  showEstimateSection: PropTypes.bool,
  subItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      availability: PropTypes.number,
      quantity: PropTypes.number,
      isSubitem: PropTypes.bool,
      inventoryComments: PropTypes.string,
      cost: PropTypes.number,
    })
  ),
  total: PropTypes.number,
  thumbnail: PropTypes.shape({
    iconType: PropTypes.string,
    resource: PropTypes.string,
  }),
  qsValues: PropTypes.shape({
    roNumberEncode: PropTypes.string,
    iv: PropTypes.bool,
  }),
}

const InspectionItem = withConnect([RepairShopContainer])(
  withQueryString(InspectionItemStateLess)
)
export { InspectionItem }
