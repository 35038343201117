import styled from 'styled-components'
import { AppBar } from '@material-ui/core'

export const FooterBarSectionDro = styled(AppBar)`
  background-color: #ffffff;
  bottom: 0;
  top: auto;
  height: 72px;

  & > #foodbar--toolbar {
    height: 72px;

    & > div > div:first-child {
      padding-right: 0px;
    }
  }
`
