import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { PlaylistAddCheck as PlaylistAddCheckIcon } from '@material-ui/icons'

import { TopBar } from '../../components/TopBar'
import { LabelDefault, LabelPrimary } from '../../components/Labels'
import { ChipButton } from '../../components/ChipButton'
import { MainSectionStyle } from '../../components/MainSection'
import { FooterBar } from '../../components/FooterBar'
import { Modal } from '../../components/Modal'
import { BasicLoader } from '../../components/BasicLoader'

import { InspectionItemsList } from './components/InspectionItemsList'

import { ApprovalModalContent } from './components/ApprovalModalContent'
import { EvidenceModal } from './components/EvidenceModal'
import { InspectionItemsStyle } from './styles'

import { InspectionItemContainer } from '../../containers/inspectionItem.container'
import { withMainData } from '../../HOC/withMainData'
import { withSizeViewPort } from '../../HOC/withSizeViewPort'

import str from '../../utils/str'
import NotesAboutEstimate from './components/NotesAboutEstimate'

class InspectionItemsView extends Component {
  constructor() {
    super()
    this.state = {
      openApprovalModal: false,
      openEvidenceModal: false,
      itemForModal: {
        comments: [],
        id: 0,
        media: [],
        name: '',
      },
    }

    this.handleApprovalModalStatusClick = this.handleApprovalModalStatusClick.bind(
      this
    )
    this.handleEvidenceModalCloseClick = this.handleEvidenceModalCloseClick.bind(
      this
    )
    this.handleOpenEvidenceModalOpenClick = this.handleOpenEvidenceModalOpenClick.bind(
      this
    )
    this.handleAddInspectionItemClick = this.handleAddInspectionItemClick.bind(
      this
    )
    this.handleAprroveEvidenceClick = this.handleAprroveEvidenceClick.bind(this)
    this.opened = this.opened.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.repairOrderData.loadedData) {
      const [, , , InspectionItemContainer] = this.props.containers
      if (
        !InspectionItemContainer.state.loadingData &&
        !InspectionItemContainer.state.loadedData
      ) {
        InspectionItemContainer.getFormatedDataFromRepairs(
          this.props.repairOrderData.repairs
        )
      }
    }
  }

  costLabel({ t, numberItems, inspectionItemsSelectedTotal }) {
    return (
      <>
        <LabelDefault size="s1">
          {' '}
          {t('inspectionItems.informationSection.itemsSelectedLabel', {
            count: numberItems,
          })}
        </LabelDefault>
        <LabelPrimary>{inspectionItemsSelectedTotal}</LabelPrimary>
      </>
    )
  }

  handleApprovalModalStatusClick() {
    const [RepairOrderContainer] = this.props.containers

    if (!RepairOrderContainer.state.loadingInspectionItemsToApprove) {
      this.setState({ openApprovalModal: !this.state.openApprovalModal })

      if (RepairOrderContainer.state.loadedInspectionItemsToApprove) {
        RepairOrderContainer.clearInspectionItemsSelected()
      }
    }
  }

  handleAprroveEvidenceClick() {
    const [RepairOrderContainer] = this.props.containers

    RepairOrderContainer.approveInspectionItems()
  }

  handleEvidenceModalCloseClick() {
    this.setState({ openEvidenceModal: !this.state.openEvidenceModal })
  }

  async handleOpenEvidenceModalOpenClick(item) {
    if (item.comments.length || item.media.length) {
      this.setState({
        itemForModal: { ...item /* , ...obtainedItem  */ },
        openEvidenceModal: !this.state.openEvidenceModal,
      })
    }
  }

  handleAddInspectionItemClick(id, masterItemId, name, total, status) {
    const [
      RepairOrderContainer,
      ,
      ,
      InspectionItemContainer,
    ] = this.props.containers
    if (InspectionItemContainer.approvalStatus.added === status) {
      RepairOrderContainer.addInspectionItemSelected({
        id,
        masterItemId,
        name,
        total,
      })
    } else if (InspectionItemContainer.approvalStatus.add === status) {
      RepairOrderContainer.removeInspectionItemSelected({
        id,
        masterItemId,
        name,
        total,
      })
    }

    InspectionItemContainer.changeApprovalStatus(id, status)
  }

  opened(priority) {
    return (
      this.props.location.state &&
      this.props.location.state.openTap === priority
    )
  }

  render() {
    const CostLabel = this.costLabel
    const { repairOrderData, repairShopData } = this.props
    const { t, currentViewport } = this.props
    const maxWidth = currentViewport.down.md
      ? 'md'
      : currentViewport.down.lg
      ? 'lg'
      : currentViewport.down.xl
      ? 'xl'
      : false
    const [
      RepairOrderContainer,
      ,
      ,
      InspectionItemContainer,
    ] = this.props.containers
    const _n = repairShopData.removeDecimals ? 0 : 2
    const totalApprovedStr = str.format(
      repairShopData.currency,
      str.numberFormat(
        repairOrderData.totalApproved,
        _n,
        3,
        repairShopData.groupSeparator,
        repairShopData.decimalSeparator
      )
    )
    const inspectionItemsSelectedTotalStr = str.format(
      repairShopData.currency,
      str.numberFormat(
        repairOrderData.inspectionItemsSelectedTotal,
        _n,
        3,
        repairShopData.groupSeparator,
        repairShopData.decimalSeparator
      )
    )
    const showEstimateFlag = !(
      repairShopData.enableEstimateReview &&
      !repairOrderData.showEstimateToConsumer
    )

    return (
      <>
        <TopBar />
        <MainSectionStyle>
          <InspectionItemsStyle container direction="column">
            <Grid
              container
              className="inspectionItems--informationSection"
              xs={12}
              sm={11}
              item
            >
              {repairShopData.showConsumerInformation && (
                <Grid
                  className="inspectionItems--typeCarSection"
                  item
                  xs={12}
                  sm={4}
                  md={3}
                >
                  <LabelDefault size="s1">{repairOrderData.make}</LabelDefault>
                  <LabelPrimary size="s3">
                    {repairOrderData.model}, {repairOrderData.year}
                  </LabelPrimary>
                </Grid>
              )}
              {RepairOrderContainer.state.totalApproved ? (
                <Grid
                  className="inspectionItems--amountApprovedSection"
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={2}
                >
                  <LabelDefault size="s1">
                    {t('inspectionItems.informationSection.totalAprovedLabel')}
                  </LabelDefault>
                  <LabelPrimary size="s2">{totalApprovedStr}</LabelPrimary>
                </Grid>
              ) : null}
              {currentViewport.between.sm_xl ? (
                <>
                  <Grid
                    className="inspectionItems--InspectionItemsSelectedSection"
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={2}
                  >
                    <LabelDefault size="s1">
                      {t(
                        'inspectionItems.informationSection.itemsSelectedLabel',
                        {
                          count: repairOrderData.inspectionItemsSelected.length,
                        }
                      )}
                    </LabelDefault>
                    <LabelPrimary size="s2">
                      {inspectionItemsSelectedTotalStr}
                    </LabelPrimary>
                  </Grid>
                  <Grid
                    className="inspectionItems--ApprovalBtnSection"
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <ChipButton
                      size="medium"
                      startIcon={<PlaylistAddCheckIcon />}
                      type="primary"
                      variant="contained"
                      onClick={this.handleApprovalModalStatusClick}
                      disabled={!repairOrderData.inspectionItemsSelectedTotal}
                    >
                      {t(
                        'inspectionItems.informationSection.authorizeRepairsButton'
                      )}
                    </ChipButton>
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Grid
              container
              className="inspectionItems--listsSection"
              direction="column"
              item
            >
              {InspectionItemContainer.state.loadedData ? (
                <>
                  <Grid>
                    <InspectionItemsList
                      accordionMode={currentViewport.between.sm_xl}
                      inspectionItems={InspectionItemContainer.state.urgent}
                      priority="urgent"
                      open={this.opened('urgent')}
                      showEstimate={showEstimateFlag}
                      onOpenEvidenceModalClick={
                        this.handleOpenEvidenceModalOpenClick
                      }
                      onAddInspectionItemClick={
                        this.handleAddInspectionItemClick
                      }
                      title={t(
                        'inspectionItems.inspectionItemLists.urgentSectionTitle'
                      )}
                    />
                  </Grid>
                  <Grid>
                    <InspectionItemsList
                      accordionMode={currentViewport.between.sm_xl}
                      inspectionItems={InspectionItemContainer.state.suggested}
                      priority="suggested"
                      showEstimate={showEstimateFlag}
                      open={this.opened('suggested')}
                      onOpenEvidenceModalClick={
                        this.handleOpenEvidenceModalOpenClick
                      }
                      onAddInspectionItemClick={
                        this.handleAddInspectionItemClick
                      }
                      title={t(
                        'inspectionItems.inspectionItemLists.suggestedSectionTitle'
                      )}
                    />
                  </Grid>
                  <Grid>
                    <InspectionItemsList
                      accordionMode={currentViewport.between.sm_xl}
                      inspectionItems={InspectionItemContainer.state.low}
                      priority="good"
                      open={this.opened('good')}
                      onOpenEvidenceModalClick={
                        this.handleOpenEvidenceModalOpenClick
                      }
                      title={t(
                        'inspectionItems.inspectionItemLists.goodConditionSectionTitle'
                      )}
                    />
                  </Grid>
                  <NotesAboutEstimate
                    notes={RepairOrderContainer.state.estimateNotes}
                  />
                </>
              ) : (
                <BasicLoader
                  className="inspectionItems--listsSection--basicLoader"
                  sizeLoader="big"
                  label={t('common.load.common')}
                />
              )}
            </Grid>
          </InspectionItemsStyle>
        </MainSectionStyle>
        <FooterBar
          button={
            <ChipButton
              size="small"
              startIcon={<PlaylistAddCheckIcon />}
              type="primary"
              variant="contained"
              onClick={this.handleApprovalModalStatusClick}
              disabled={!repairOrderData.inspectionItemsSelectedTotal}
            >
              {t('inspectionItems.informationSection.authorizeRepairsButton')}
            </ChipButton>
          }
          informationLabels={
            <CostLabel
              t={t}
              numberItems={repairOrderData.inspectionItemsSelected.length}
              inspectionItemsSelectedTotal={inspectionItemsSelectedTotalStr}
            />
          }
        />
        <Modal
          open={this.state.openApprovalModal}
          keepMounted
          onClose={this.handleApprovalModalStatusClick}
          aria-labelledby="Approval Modal"
          aria-describedby="Inspection Items will be approved"
        >
          <ApprovalModalContent
            items={RepairOrderContainer.state.inspectionItemsSelected}
            currencyFormat={repairShopData.currency}
            total={RepairOrderContainer.state.inspectionItemsSelectedTotal}
            onApproveClick={this.handleAprroveEvidenceClick}
            onCancelClick={this.handleApprovalModalStatusClick}
            loadingInspectionItemsToApprove={
              RepairOrderContainer.state.loadingInspectionItemsToApprove
            }
            loadedInspectionItemsToApprove={
              RepairOrderContainer.state.loadedInspectionItemsToApprove
            }
          />
        </Modal>
        <Modal
          maxWidth={maxWidth}
          open={this.state.openEvidenceModal}
          keepMounted
          onClose={this.handleEvidenceModalCloseClick}
          aria-labelledby="Evidence Modal"
          aria-describedby="Evidences relationed to the inspection item"
        >
          <EvidenceModal
            item={this.state.itemForModal}
            onClose={this.handleEvidenceModalCloseClick}
            isOpenModal={this.state.openEvidenceModal}
          />
        </Modal>
      </>
    )
  }
}

export const InspectionItems = withTranslation()(
  withMainData(withSizeViewPort(InspectionItemsView), {
    containers: [InspectionItemContainer],
  })
)
