import { Container } from 'unstated'
import { RepairShopService } from '../services/RepairShop.service'

class RepairShopContainer extends Container {
  constructor() {
    super()

    this.repairShopService = new RepairShopService()
    this.state = {
      taxIdentification: '',
      showDefaltItems: false,
      address2: '',
      address1: '',
      fiscalAddress: '',
      name: '',
      accountNameDisplayed: '',
      businessName: '',
      id: 0,
      phone: '',
      businessPhone: '',
      serviceHoursMondayToFriday: '',
      serviceHoursSaturday: '',
      loadedData: false,
      loadingData: false,
      taxPercentage: 0,
      languageCode: '',
      currency: '',
      enableEstimateReview: false,
      decimalSeparator: '',
      groupSeparator: '',
      consumerFormLogo: '',
      showVehicleMakeLogo: '',
      approveOnlyEstimated: false,
      customerApprovalEmails: '',
      showConsumerInformation: false,
      showKarloPaymentStatus: false,
      removeDecimals: false,
      karloAccount: '',
      emailUserLoginKarlo: '',
      passwordUserLoginKarlo: '',
    }
  }

  getData(id) {
    this.setState({ loadingData: !this.state.loadedData })
    return this.repairShopService
      .getRepairShop(id)
      .then((data) => {
        return this.setState({
          ...data,
          loadedData: !this.state.loadedData,
          loadingData: !this.state.loadedData,
        })
      })
      .then(() => this.state)
      .finally(() => {
        this.setState({ loadingData: !this.state.loadedData })
      })
  }

  async setData(repairShop) {
    await this.setState({ ...repairShop })
    return this.state
  }
}

export { RepairShopContainer }
