import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Avatar, Button, Grid } from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import {
  ApprovalModalContentStyle,
  CheckCircleOutlineIconStyle,
  CircularProgressStyle,
} from './styles'

import str from '../../../../utils/str'
import { withConnect } from '../../../../HOC/withConnect'
import { RepairShopContainer } from '../../../../containers/repairShop.container'

function ApproveButtonGroup({
  loadingInspectionItemsToApprove,
  loadedInspectionItemsToApprove,
  onApproveClick,
  onCancelClick,
}) {
  const { t } = useTranslation()

  if (loadingInspectionItemsToApprove) {
    return <CircularProgressStyle />
  } else if (loadedInspectionItemsToApprove) {
    return (
      <>
        <CheckCircleOutlineIconStyle />
        <Button
          className="approvalModalContent--button AMC--button--cancel"
          onClick={onCancelClick}
          variant="contained"
        >
          {t('inspectionItems.approvalModal.closeBTNLabel')}
        </Button>
      </>
    )
  } else {
    return (
      <>
        <Button
          className="approvalModalContent--button AMC--button--approve"
          color="primary"
          onClick={onApproveClick}
          variant="contained"
        >
          {t('inspectionItems.approvalModal.authorizeBTNLabel')}
        </Button>
        <Button
          className="approvalModalContent--button AMC--button--cancel"
          onClick={onCancelClick}
          variant="contained"
        >
          {t('inspectionItems.approvalModal.cancelBTNLabel')}
        </Button>
      </>
    )
  }
}

function ApprovalModalContentStateLess({
  containers,
  items,
  total = 0,
  onApproveClick,
  onCancelClick,
  loadingInspectionItemsToApprove,
  loadedInspectionItemsToApprove,
}) {
  const { t } = useTranslation()
  const [RepairShopContainer] = containers

  return (
    <ApprovalModalContentStyle container direction="column" justify="center">
      <Avatar
        component={Grid}
        className="approvalModalContent--iconSection"
        item
      >
        <CheckIcon />
      </Avatar>
      <Grid className="approvalModalContent--titleSection" item>
        <h3>{t('inspectionItems.approvalModal.title')}</h3>
      </Grid>
      <Grid
        className="approvalModalContent--inspectionItemsSection"
        container
        item
        direction="column"
        justify="center"
        wrap="nowrap"
      >
        <div className="approvalModalContent--itemsWrapper">
          {items.map((item, index) => (
            <div key={`AMC-I-${index}`}>
              <p>{item.name}</p>
              <p>
                {str.format(
                  RepairShopContainer.state.currency,
                  str.numberFormat(
                    item.total,
                    2,
                    3,
                    RepairShopContainer.state.groupSeparator,
                    RepairShopContainer.state.decimalSeparator
                  )
                )}
              </p>
            </div>
          ))}
        </div>
      </Grid>
      <Grid
        container
        className="approvalModalContent--totalSection"
        item
        justify="space-between"
        alignItems="center"
      >
        <p>{t('inspectionItems.approvalModal.totalLabel')}</p>
        <p>
          {str.format(
            RepairShopContainer.state.currency,
            str.numberFormat(
              total,
              2,
              3,
              RepairShopContainer.state.groupSeparator,
              RepairShopContainer.state.decimalSeparator
            )
          )}
        </p>
      </Grid>
      <Grid
        item
        container
        direction="column"
        justify="center"
        style={{ flexWrap: 'nowrap' }}
        alignItems="center"
      >
        <ApproveButtonGroup
          loadingInspectionItemsToApprove={loadingInspectionItemsToApprove}
          loadedInspectionItemsToApprove={loadedInspectionItemsToApprove}
          onApproveClick={onApproveClick}
          onCancelClick={onCancelClick}
        />
      </Grid>
    </ApprovalModalContentStyle>
  )
}

ApprovalModalContentStateLess.propTypes = {
  containers: PropTypes.array,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      total: PropTypes.number.isRequired,
    })
  ),
  total: PropTypes.number,
  onApproveClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  loadingInspectionItemsToAprrove: PropTypes.bool,
  loadedInspectionItemsToAprrove: PropTypes.bool,
}

const ApprovalModalContent = withConnect([RepairShopContainer])(
  ApprovalModalContentStateLess
)
export { ApprovalModalContent }
