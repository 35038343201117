import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {
  LabelDefault,
  LabelPrimary,
  LabelTitlePrimary,
} from '../../../../components/Labels'

import { RepairOrderRecapGridStyled } from './styles'

function RenderCostumerInformation({
  make,
  model,
  year,
  plates,
  mileage,
  vin,
  showConsumerInformation,
}) {
  const { t } = useTranslation()
  const numberWithCommas = (number) =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return showConsumerInformation ? (
    <>
      <Grid id="repairOrderRecap--roDataSection--carBrand" item>
        <LabelDefault size="s1">{make}</LabelDefault>
        <LabelPrimary size="s3">
          {model}, {year}
        </LabelPrimary>
      </Grid>
      <Grid
        id="repairOrderRecap--roDataSection--carData"
        container
        item
        justify="flex-start"
        spacing={1}
      >
        {plates ? (
          <Grid item xs={3} sm={3} md={2} lg={2}>
            <LabelDefault size="s1">
              {t('home.repairOrderRecap.plates')}
            </LabelDefault>
            <LabelPrimary size="s1">{plates}</LabelPrimary>
          </Grid>
        ) : null}
        {mileage ? (
          <Grid item xs={3} sm={3} md={2} lg={2}>
            <LabelDefault size="s1">
              {t('home.repairOrderRecap.mileage')}
            </LabelDefault>
            <LabelPrimary size="s1">
              {numberWithCommas(mileage)}{' '}
              {t('common.units.abbreviation.mileage')}
            </LabelPrimary>
          </Grid>
        ) : null}
        {vin ? (
          <Grid item xs={6} sm={5} md={4} lg={4}>
            <LabelDefault size="s1">
              {t('home.repairOrderRecap.vinNumber')}
            </LabelDefault>
            <LabelPrimary size="s1">{vin}</LabelPrimary>
          </Grid>
        ) : null}
      </Grid>
    </>
  ) : null
}
function RepairOrderRecap({
  consumerName,
  consumerLastName,
  roNumber,
  make,
  model,
  year,
  plates,
  mileage,
  vin,
  showConsumerInformation,
}) {
  const { t } = useTranslation()
  consumerName = showConsumerInformation ? consumerName : ''
  consumerLastName = showConsumerInformation ? consumerLastName : ''
  return (
    <RepairOrderRecapGridStyled
      container
      direction="column"
      alignItems="baseline"
    >
      <Grid id="repairOrderRecap--customerName" item>
        <LabelTitlePrimary>
          {t('home.repairOrderRecap.welcomeText', {
            consumerName,
            consumerLastName,
          })}
        </LabelTitlePrimary>
      </Grid>
      <Grid id="repairOrderRecap--roDataSection--id" item>
        <LabelPrimary size="s2">
          {t('home.repairOrderRecap.sectionTitle')}
        </LabelPrimary>
        <LabelDefault size="s1">
          {t('home.repairOrderRecap.roLabel', { roNumber })}
        </LabelDefault>
      </Grid>
      <RenderCostumerInformation
        make={make}
        model={model}
        year={year}
        plates={plates}
        mileage={mileage}
        vin={vin}
        showConsumerInformation={showConsumerInformation}
      />
    </RepairOrderRecapGridStyled>
  )
}

RepairOrderRecap.propType = {
  consumerName: PropTypes.string,
  consumerLastName: PropTypes.string,
  roNumber: PropTypes.number,
  make: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.number,
  plates: PropTypes.string,
  mileage: PropTypes.number,
  vin: PropTypes.string,
  showConsumerInformation: PropTypes.bool,
}
export { RepairOrderRecap }
