import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

import { mediaQuery } from '../../styles/helpers/mediaQuery'

export const ModalStyle = styled(Dialog)`
  &.MuiDialog-root {
    z-index: 100000 !important;
  }

  .MuiDialog-container {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .MuiDialog-paper {
    overflow-y: initial;
  }

  ${mediaQuery('sm')`
    .MuiDialog-paper{
      // margin-top: 80px;   
    }
    `}
`
