import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { mediaQuery } from '../../../../styles/helpers/mediaQuery'

export const RepairOrderRecapGridStyled = styled(Grid)`
  overflow: hidden;

  #repairOrderRecap--customerName{
    margin-bottom: 24px;
    max-width: 100%;
  }

  #repairOrderRecap--roDataSection{
    &--id{
      margin-bottom: 16px;
      max-width: 100%;
    }

    &--carBrand{
      margin-bottom: 12px;
      max-width: 100%;
    }
  }
  
  ${mediaQuery('sm')`
      background-color: #ffffff;
      padding: 32px 0 12px 40px;
      border-bottom-right-radius: 20px;

      #repairOrderRecap--customerName{
        margin-bottom: 16px;
      }

      #repairOrderRecap--roDataSection{
        
        &--carData{
          & > div {
            background-color: #efeff3;
            border-radius: 4px;
            margin-right: 16px;
            padding: 8px 16px;
            margin-bottom: 12px;
          }
        }
      }
    `}

${mediaQuery('md')`
      padding: 32px 0 20px 80px;

      #repairOrderRecap--customerName{
        margin-bottom: 24px;
      }

      #repairOrderRecap--roDataSection{
        
        &--id{
          margin-bottom: 24px;
        }
        &--carBrand{
          margin-bottom: 16px;
        }

        &--carData{
          & > div {
            padding: 12px 16px;
            & > p:first-child{
              font-size: 16px;
            }
          }
        }
      }
    `}

${mediaQuery('lg')`
      padding-left: 104px;

      #repairOrderRecap--roDataSection{
        &--id{
          & > p:first-child{
            font-size: 20px;
          }
          & > p:nth-child(2){
            font-size: 16px;
          }
        }
        &--carBrand{
          margin-bottom: 24px;
          & > p:first-child{
            font-size: 16px;
          }
          & > p:nth-child(2){
            font-size: 21px;
          }
        }
      }
    `}

`
