import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { breakpoints } from '../../styles/helpers/mediaQuery'
import backgroundSquareOne from '../../assets/images/background-square-one.png'
import backgroundSquareTwo from '../../assets/images/background-square-two.png'
import karloLogo from '../../assets/images/karlo-logo.png'

export const HomeGridStyle = styled(Grid)`
  padding: 32px 16px 0 19px;

  & > div{
    margin-bottom: 20px;
    width: 100%;
  }

  & > .homeGrid--documentsSection{
    margin-bottom: 70px;
    flex-wrap: nowrap;
    & > div{
      align-items: flex-start;
      justify-content: flex-start;
      width: 60%;
    }
    .links{
      width:850px;
      margin-bottom:18px;
    }
    .additionalNotes{
      width: 286px;
      border: 2px solid rgb(18, 26, 65);
      line-height: 24px;
      height: 114px;
      overflow: auto;
      margin-right: 80px;
      margin-bottom: 20px;
      border-radius: 10px;
      padding: 5px 12px;
    }
  }

  & > .homeGrid--footerSection{
    display: none;
    color: #85818c;
    padding-right: 70px;
    padding-left: 70px;
    margin-bottom: 10px;
    padding-top: 18px;
    justify-content: center;
    & > div{
      border-top: solid 1px #85818c4f;
      padding-top: 15px;
      justify-content: center;
    }
    .text{
      height: 15px;
      position: relative;
      top: 5px;
    }
    .logo{
      background: url(${karloLogo}) no-repeat 9% 97%;
      background-image: url(/dist/imgs/karlo-logo.png);
      width: 47px; 
      height: 40px; 
      position: relative;
      top: -3px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  @media (min-width: ${breakpoints.sm}px) { 
      padding: 0;
      background-image: url(${backgroundSquareOne}),  url(${backgroundSquareTwo});
      background-position: 97% 30px, 9% 97%;
      background-repeat: no-repeat;
      min-height: 90vh;
      
      & > div{
        margin-bottom: 32px;
      }
  }

  @media (min-width: ${breakpoints.md}px) { 
      
      & > .homeGrid--footerSection{
        display: block;
      }
      & > div{
        margin-bottom: 32px;
      }
  }

  @media (min-width: ${breakpoints.lg}px) { 
    .homeGrid--documentsSection{
      .links{
        margin-left: 104px;
      }
    }
 }

 @media ((min-width: ${breakpoints.md}px) and (max-width: ${
  breakpoints.lg - 1
}px)) { 
  .homeGrid--documentsSection{
    .links{
      margin-left: 80px;
    }
  }
}

@media ((min-width: ${breakpoints.xs}px) and (max-width: ${
  breakpoints.md - 1
}px)) { 
  .homeGrid--documentsSection{
    flex-direction: column;
    .links{
      margin-left: 30px;
      width:auto;
    }
    .additionalNotes{
      margin-left: 30px;
      margin-right: 30px;
      width: auto;
    }
  }
}


  @media (min-width: ${breakpoints.xl}px) { 
    & > .homeGrid--footerSection{
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }
 }
  @media (max-width: 1216px) and (min-width: 961px){
    & > .homeGrid--documentsSection{
      & > div{
        align-items: flex-start;
        justify-content: flex-start;
        width: 60%;
      }
      .links{
        width:auto;
        flex-wrap: nowrap;
      }
      .additionalNotes{
        width: 22%;
        margin-left:auto;
      }
    }
  }

`
