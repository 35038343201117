import styled from 'styled-components'
import { Avatar, Grid } from '@material-ui/core'

import { mediaQuery } from '../../../../styles/helpers/mediaQuery'

export const LabelButtonStyle = styled(Grid)`
  display: flex;
  cursor: pointer;
  margin-bottom: 12px;
  margin-right: 24px;
  justify-content: start;

  & > a {
    text-decoration: none;
    display: contents;
  }

  ${mediaQuery('sm')`
      margin-right: 20px;
    `}
`
export const IconButtonLBStyle = styled(Avatar)`
  color: #121a41;
  background-color: #efeff3;
  border-radius: 10px;
  margin-right: 12px;

  ${mediaQuery('sm')`
      background-color: #ffffff;
      height: 56px;
      margin-right: 14px;
      width: 56px;

      & > .MuiSvgIcon-root{
        font-size: 32px; 
      }
    `}

  ${mediaQuery('md')`
      height: 72px;
      width: 72px;
      margin-right: 16px;

      & > .MuiSvgIcon-root{
        font-size: 40px; 
      }
    `}
`
