import React from 'react'
import PropTypes from 'prop-types'

import { InformationAreaStyle } from './styles'
import { LabelDefault, LabelPrimary } from '../../components/Labels'

function InformationArea({ children, bodyText, labelText }) {
  return (
    <InformationAreaStyle>
      <LabelDefault className="informationArea--label" size="s1">
        {labelText}
      </LabelDefault>
      {bodyText ? <LabelPrimary>{bodyText}</LabelPrimary> : children}
    </InformationAreaStyle>
  )
}

InformationArea.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  bodyText: PropTypes.string,
  labelText: PropTypes.string.isRequired,
}

export { InformationArea }
