import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons'

import { BackButtonStyled } from './styles'

function BackButton({ label, icon = ArrowBackIosIcon, ...props }) {
  const Icon = icon

  return (
    <BackButtonStyled
      {...props}
      startIcon={<Icon size="small" />}
      component={Link}
    >
      {label}
    </BackButtonStyled>
  )
}

BackButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
}

export { BackButton }
