import axios from 'axios'
import UAParser from 'ua-parser-js'

class BaseService {
  constructor(uri = '', url = '') {
    const parser = new UAParser()
    this.uri = uri
    this.url = url || process.env.URL_SERVER

    this.headers = {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'User-Agent': parser.getUA(),
      'x-api-key': process.env.X_API_KEY,
    }

    this.axios = axios.create({
      baseURL: this.url,
      headers: this.headers,
    })
  }

  clearHeaders() {
    this.headers = {
      'Content-Type': 'application/json',
    }
  }

  setToken(token) {
    this.clearHeaders()
    if (token != '') {
      this.headers.Authorization = 'Bearer ' + token
    }
    this.axios = axios.create({
      baseURL: this.url,
      headers: this.headers,
    })
  }

  setUrl(url) {
    this.url = url
  }

  get(uri, header) {
    return this.axios.get(uri, header)
  }

  post(uri, payload, config) {
    return this.axios.post(uri, payload, config)
  }
}

export { BaseService }
