import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const sizeLoader = {
  small: {
    height: '40px',
    width: '40px',
  },
  medium: {
    height: '50px',
    width: '50px',
  },
  big: {
    height: '60px',
    width: '60px',
  },
}

export const BasicLoaderStyle = styled(CircularProgress)`
  color: #0048c0;

  & > .MuiCircularProgress-svg {
    width: ${(props) => sizeLoader[props.size || 'small'].width} !important;
    height: ${(props) => sizeLoader[props.size || 'small'].height} !important;
  }
`
export const BasicLoaderWrapperStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > span {
    display: inline-block;
    margin: 50px 0;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
`
