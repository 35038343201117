import React from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

import { getDisplayNameHOC } from '../utils/getDisplayNameHOC'

function withQueryString(WrappedComponent) {
  function qs(props) {
    const location = useLocation()
    const params = queryString.parse(location.search, { decode: false })
    const queryStringValues = {
      roNumberEncode: params.params,
      iv: params.iv,
    }

    return <WrappedComponent {...props} qsValues={queryStringValues} />
  }

  qs.displayName = `withQueryString(${getDisplayNameHOC(WrappedComponent)})`

  return qs
}

export { withQueryString }
