const breakpoints = {
  xs: 0,
  sm: 600 + 1,
  md: 960 + 1,
  lg: 1280 + 1,
  xl: 1920 + 1,
}

function mediaQuery(sizeKey) {
  return (styleString) =>
    `@media (min-width: ${breakpoints[sizeKey]}px) { ${styleString} }`
}

export { breakpoints, mediaQuery }
