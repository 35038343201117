import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { breakpoints } from '../../styles/helpers/mediaQuery'
import backgroundSquareOne from '../../assets/images/background-square-one.png'
import backgroundSquareTwo from '../../assets/images/background-square-two.png'

export const WorkshopInformationContainerStyle = styled(Grid)`
  padding: 0px 24px;

  > div:first-child {
    margin-top: 60px;
  }

  .workshopInformation-- {
    &navBar {
      margin-bottom: 36px;
    }

    &nameSection {
      margin-bottom: 28px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > p {
        margin: 8px;
      }
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    background-image: url(${backgroundSquareOne}), url(${backgroundSquareTwo});
    background-position: 97% 100px, 4% 97%;
    background-repeat: no-repeat;

    background-color: #ffffff;
    flex-direction: column;
    padding-left: 92px;
    min-height: 100vh;

    .workshopInformation--nameSection {
      margin-bottom: 28px;
      & > p {
        margin: 12px;
        font-size: 24px;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    padding-left: 112px;
    .workshopInformation--nameSection {
      & > p {
        font-size: 28px;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding-left: 132px;

    .workshopInformation--nameSection {
      & > p {
        margin: 16px;
      }
    }
  }
`
