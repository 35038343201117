import styled from 'styled-components'

import { breakpoints } from '../../../../styles/helpers/mediaQuery'

const priorityColor = {
  urgent: '#e84661',
  good: '#25bc73',
  suggested: '#dbb012',
}

export const ShoppingCartStyle = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  height: 164px;
  margin: 8px 8px;
  padding: 16px;
  width: 136px;
  min-width: 136px;
  position: relative;
  left 30px;
  display: none;
  .shoppingCard{
    &--section-content{
      height: 100px;
      margin-bottom: 20px;
    }
    &--section-action{
      position: relative;
      bottom: -5px; 
    }
  }

  @media (min-width: ${breakpoints.sm}px) { 
      height: 215px;
      margin: 20px 16px;
      padding: 16px 24px 8px 24px;
      width: 251px;
      display: block;
  }

  @media (min-width: ${breakpoints.lg}px) {
      height: 235px;
      width: 251px;
      .shoppingCard{
        &--section-action{
          bottom: -35px; 
        }
      }
  }
`
