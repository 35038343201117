import React from 'react'
import { NotFoundStyle } from './styles'

function NotFound() {
  return (
    <NotFoundStyle>
      <div id="errorCode-section">
        <h1>404</h1>
      </div>
      <div id="message-section">
        <p id="message-expression">Ooops!!!!</p>
        <p id="message-text">This resource is not available</p>
      </div>
    </NotFoundStyle>
  )
}

export { NotFound }
