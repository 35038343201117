import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { mediaQuery } from '../styles/helpers/mediaQuery'

export const MainSectionStyle = styled(Grid)`
  > div:first-child {
    padding-top: 50px;
  }

  ${mediaQuery('sm')`
      background-color: #efeff3;
      min-height: 100vh;

      > div:first-child{
        padding-top: 60px;
        min-height: 100vh;
      }
  `}

  ${mediaQuery('md')`
    > div:first-child{
      padding-top: 72px;
    }
  `}
`
