import { fontFace } from './helpers/fontFace'
import latoRegular from '../assets/fonts/Lato/Lato-Regular.ttf'
import helveticaRegular from '../assets/fonts/Helvetica/Helvetica-regular.ttf'
import helveticaBold from '../assets/fonts/Helvetica/Helvetica-Bold.ttf'
import latoBold from '../assets/fonts/Lato/Lato-Bold.ttf'

export const defineFonts = function () {
  return `
    ${fontFace('Lato', latoRegular)}

    ${fontFace('Lato', latoBold, 'bold')}

    ${fontFace('Helvetica', helveticaRegular)}
    
    ${fontFace('Helvetica', helveticaBold, 'bold')}
    
  `
}
