import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

function withSizeViewPort(WrappedComponet) {
  return function (props) {
    const theme = useTheme()
    const currentViewport = {
      between: {
        sm_xl: useMediaQuery(theme.breakpoints.between('sm', 'xl')),
      },
      down: {
        md: useMediaQuery(theme.breakpoints.down('md')),
        lg: useMediaQuery(theme.breakpoints.down('lg')),
        xl: useMediaQuery(theme.breakpoints.down('xl')),
      },
    }

    return <WrappedComponet {...props} currentViewport={currentViewport} />
  }
}

export { withSizeViewPort }
