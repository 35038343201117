import React from 'react'
import PropTypes from 'prop-types'
import { navigate, useLocation } from '@reach/router'
import { KeyboardArrowRight } from '@material-ui/icons'

import { LabelPrimary } from '../../../../components/Labels'

import { CardStyle } from './styles'

function InspectionItemCard({
  amount = '0',
  priority = 'urgent',
  name,
  seeMoreLabel = 'See more',
  type = 'Repairs',
}) {
  const location = useLocation()
  const handlerClick = () => {
    navigate('/digital-repair-order/inspection-items/' + location.search, {
      state: { openTap: priority },
    })
  }

  return (
    <CardStyle
      style={{ cursor: amount ? 'pointer' : 'default' }}
      priority={priority}
      onClick={amount ? handlerClick : null}
    >
      <section className="inspectionItemCard--section-content">
        <div className="inspectionItemCard--items-amount">
          <p>{amount}</p>
        </div>
        <div className="inspectionItemCard--items-type">
          <LabelPrimary size="s1" bold={false}>
            {type}
          </LabelPrimary>
          <LabelPrimary>{name}</LabelPrimary>
        </div>
      </section>
      <section className="inspectionItemCard--section-action">
        <LabelPrimary size="s1">
          {' '}
          {seeMoreLabel} <KeyboardArrowRight fontSize="small" />
        </LabelPrimary>
      </section>
    </CardStyle>
  )
}

InspectionItemCard.propTypes = {
  amount: PropTypes.string,
  priority: PropTypes.string,
  name: PropTypes.string.isRequired,
  seeMoreLabel: PropTypes.string,
  type: PropTypes.string,
}

export { InspectionItemCard }
