import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Slider from 'react-slick'

import { TotalInfoCard } from '../TotalInfoCard'
import { InspectionItemCard } from '../InspectionItemCard/'
import { InspectionItemsRecapStyle } from './styles'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  arrows: false,
  dots: false,
  infinite: false,
  variableWidth: true,
  slidesToShow: 2,
  swipeToSlide: true,
}

function InspectionItemsRecap({
  repairsAmount,
  totalInfoCardAmount,
  serviceAdvisorPhone,
}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSMXLViewport = useMediaQuery(theme.breakpoints.between('sm', 'xl'))
  const seeMoreLabel = t('home.inspectionItemsRecap.cardLabelSeeMore')

  return (
    <InspectionItemsRecapStyle>
      {isSMXLViewport ? (
        <>
          <InspectionItemCard
            amount={repairsAmount.urgent}
            name={t('home.inspectionItemsRecap.urgentCardMainName')}
            priority="urgent"
            type={t('home.inspectionItemsRecap.cardLabelRepairType')}
            seeMoreLabel={seeMoreLabel}
          />
          <InspectionItemCard
            amount={repairsAmount.suggested}
            name={t('home.inspectionItemsRecap.suggestedCardMainName')}
            priority="suggested"
            type={t('home.inspectionItemsRecap.cardLabelRepairType')}
            seeMoreLabel={seeMoreLabel}
          />
          <InspectionItemCard
            amount={repairsAmount.good}
            name={t('home.inspectionItemsRecap.goodConditionCardMainName')}
            priority="good"
            type={t('home.inspectionItemsRecap.cardLabelGoodType')}
            seeMoreLabel={seeMoreLabel}
          />
          <TotalInfoCard
            buttonLabel={t(
              'home.inspectionItemsRecap.totalInfoCardButtonLabel'
            )}
            mainLabel={t('home.inspectionItemsRecap.totalInfoCardMainLabel')}
            phoneLabel={t('home.inspectionItemsRecap.cardPhoneNumberLabel')}
            phoneNumber={serviceAdvisorPhone}
            totalAmount={totalInfoCardAmount}
          />
        </>
      ) : (
        <>
          <InspectionItemCard
            amount={repairsAmount.urgent}
            name={t('home.inspectionItemsRecap.urgentCardMainName')}
            priority="urgent"
            type={t('home.inspectionItemsRecap.cardLabelRepairType')}
            seeMoreLabel={seeMoreLabel}
          />
          <InspectionItemCard
            amount={repairsAmount.suggested}
            name={t('home.inspectionItemsRecap.suggestedCardMainName')}
            priority="suggested"
            type={t('home.inspectionItemsRecap.cardLabelRepairType')}
            seeMoreLabel={seeMoreLabel}
          />
          <InspectionItemCard
            amount={repairsAmount.good}
            name={t('home.inspectionItemsRecap.goodConditionCardMainName')}
            priority="good"
            type={t('home.inspectionItemsRecap.cardLabelGoodType')}
            seeMoreLabel={seeMoreLabel}
          />
        </>
      )}
    </InspectionItemsRecapStyle>
  )
}

InspectionItemsRecap.propTypes = {
  repairsAmount: PropTypes.shape({
    good: PropTypes.number,
    urgent: PropTypes.number,
    suggested: PropTypes.number,
  }),
  totalInfoCardAmount: PropTypes.number,
  serviceAdvisorPhone: PropTypes.string,
}

export { InspectionItemsRecap }
