import styled from 'styled-components'

import { mediaQuery } from '../../styles/helpers/mediaQuery'

export const InformationAreaStyle = styled.div`
  border-radius: 4px;
  background-color: #efeff3;
  padding: 8px;
  margin-bottom: 8px;
  width: 100%;

  .informationArea--label {
    color: #616161;
    font-size: 12px;
    margin-bottom: 8px;
  }

  & > p:not(:first-child) {
    color: #000000;
    font-size: 16px;
    margin-bottom: 8px;
  }

  ${mediaQuery('sm')`
      margin-bottom: 16px;
      padding-left: 24px;
      padding-top: 16px;

      .informationArea--label{
        font-size: 16px;
      }

      & > p:not(:first-child){
        font-size: 20px;
      }
    `}

  ${mediaQuery('lg')`
      margin-bottom: 20px;
      padding-left: 28px;
      padding-top: 24px;
    `}
`
