import React from 'react'
import PropTypes from 'prop-types'
import { Toolbar, Grid, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import { FooterBarSectionDro } from './styles'

function FooterBar({ button, informationLabels }) {
  const theme = useTheme()
  const isSMXLViewport = useMediaQuery(theme.breakpoints.between('sm', 'xl'))

  return isSMXLViewport ? null : (
    <FooterBarSectionDro>
      <Toolbar id="foodbar--toolbar" component="nav">
        <Grid container alignItems="center" justify="space-between" spacing={4}>
          {/* TODO: Temporal por ticket  CM-4513 */}
          {button ? (
            <>
              <Grid item xs={5}>
                {informationLabels}
              </Grid>
              <Grid item xs={7}>
                {button}
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              {informationLabels}
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </FooterBarSectionDro>
  )
}

FooterBar.propTypes = {
  button: PropTypes.element,
  informationLabels: PropTypes.element,
}

export { FooterBar }
