import styled from 'styled-components'
import { Grid, IconButton } from '@material-ui/core'

import { mediaQuery } from '../../../../styles/helpers/mediaQuery'

export const EvidenceModalStyle = styled(Grid)`
  border-radius: 4px;
  background-color: #ffffff;
  min-width: 320px;
  min-height: 496px;

  .evidenceModal--nameSection{
    background-color: #0048c0;
    flex-basis:0%;
    display:flex;
    justify-content: center;      
    align-items:center;

    font-size: 20px;
    color: #ffffff;
    text-align: center;

    .em-closeIcon{
      color:white;
    }
    & > h3{
      overflow:hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
    }
  }
  
  .em-commentsFullView::-webkit-scrollbar{
    display: none;
  }
  .em-commentsFullView{
    position:absolute;
    bottom:0;
    width:100%;
    height:80%;
    background-color:white;
    overflow: scroll;
    padding:30px;
    border-radius:25px;

    & > .em--rowTitleComments{
      width: 100%;
      display:flex;
      justify-content: space-between;      
      align-items:center;
      margin-bottom: 25px;
      
      & > .em-mediaSliderTitle{
        font-size: 18px;
        text-decoration: none solid rgb(0, 0, 0);
      }
    }
    & .em--commentWrapper{
      width:100%;
      padding:0 10px;
      margin:20px 0px;
      min-height:20px;
    } 
  }

  .evidenceModal--mediaSection{
    background-color: #000000;
    min-height: 204px;
    padding: 10px 0;
    flex-basis:0%;
  }

  .evidenceModal--informationSection{
    padding: 16px 16px 0  16px;
    flex-basis:0%;


    & > .evidenceModal--mediaSliderWrapper{
      
      & > .em-mediaSliderTitle{
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      & .em--commentWrapper{
        color: #404040;
        font-size: 12px;
        min-height: 60px;
        width: 232px;
        word-break: break-all;
      }

      & .em--noContent{
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        
        &--media{
          margin: 3% 0px;  
        }
        &--comments{
          margin-top: 10%;
        }
      }
      
      &.em--commentSliderWrapper{
        margin: 0 auto;
        width: 248px;
        & > .em--rowTitleComments{
          width: 100%;
          display:flex;
          justify-content: space-between;        
          margin-bottom: 25px;

        }
      }

      &.em--mediaSliderWrapper{
        margin: 0 auto;
        width: 248px;
      }

    }

    & > .MuiDivider-root{
      background-color: #8c8c8c;
      margin: 16px 0px;
    }
  }

  /******Evidence Media*********/
  & .evidenceMedia--image-wrapper {
    height: 180px;
    max-width: 274px;
    display: flex;
    align-items: center;
    & > img {
      max-height: 100%;
      max-width: 100%;
      /*object-fit: contain;*/
    }
  }
  & .evidenceMedia--image--markers-overlay{
    position: absolute;
    width: inherit;
    height: inherit;

    & .markers-overlay{
      position: absolute;
      cursor: pointer;

      &__number{
        color: #FF0000;
        font-weight: bold;
        text-shadow: 1px 1px 0 #000000;
      }
    }
  }

  & .evidenceMedia--video-wrapper{
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;

    & > video{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  & .evidenceMedia--audio-wrapper{
    margin-top: 25%;
    & > audio{
      margin: auto;
      display: block;
    }
  }

  /******Evidence Media*********/
  ${mediaQuery('xs')`
      flex-wrap: nowrap;
      overflow:hidden;
      position:relative;
      .evidenceModal--nameSection{
        height: 63px;
        min-height: 63px;
        font-size: 18px;
        justify-content: space-between;      
        padding: 0px 15px;
        
        & > h3{
          max-width:250px;
        }
      }
      
      .evidenceModal--informationSection{
        flex-wrap:noWrap;
        position:relative;
        & > .evidenceModal--mediaSliderWrapper{

          &.em--commentSliderWrapper{
            
            flex-wrap: nowrap;
            overflow:hidden;

            &.em--bottomGradient{
              width:100%;
              height:35px;
              bacground-color:blue;
            }
          }
          & > .em--rowTitleComments{
            margin-bottom: 25px;
            & > .em-mediaSliderTitle{
              font-size: 18px;
            }
          }
          & .em--commentWrapper{
            width:100%;
            margin:10px 0px;
            min-height:20px;
          }
        }
      }
      .em--bottomGradient{
        width:100%;
        height:100px;
        bottom:0;
        position:absolute;

        background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 76%);
      }

    `}
  ${mediaQuery('sm')`
      flex-wrap: nowrap;
      min-width: 500px;
      min-height: 532px;
      overflow:hidden;
      position:relative;

      .evidenceModal--nameSection{
        height: 63px;
        min-height: 63px;
        font-size: 21px;
        text-decoration: none solid rgb(255, 255, 255);
        justify-content: space-between;      
        padding: 0px 20px;

        & > h3{
          max-width:405px;
        }
      }

      .evidenceModal--mediaSection{
        min-height: 224px;
      }

      .evidenceModal--informationSection{
        position:relative;
        padding: 16px 16px 0  16px;

        & > .evidenceModal--mediaSliderWrapper{
          width: 412px;

          & > .em-mediaSliderTitle{
            font-size: 21px;
            text-decoration: none solid rgb(0, 0, 0);
            margin-bottom: 25px;
          }

          & .em--commentWrapper{
            width:100%;
            font-size: 14px;
            color: #404040;
            text-decoration: none solid rgb(64, 64, 64);
            margin:10px 0px;
            min-height:20px;
          }

          &.em--commentSliderWrapper{
            
            width: 339px;
            flex-wrap: nowrap;
            overflow:hidden;
          }

          &.em--mediaSliderWrapper{
            width: 339px;
          }

        }

        & > .MuiDivider-root{
          margin: 20px 0px;
        }
      }

      .em--bottomGradient{
        width:100%;
        height:100px;
        bottom:0;
        position:absolute;

        background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 76%);
      }

      /******Evidence Media*********/
      & .evidenceMedia--image-wrapper {
        max-width: 224px;
        margin: 0 auto;
        height: 200px;
      }

      & .evidenceMedia--video-wrapper{
        padding-bottom: 38.25%;
      }

      & .evidenceMedia--audio-wrapper{
        margin-top: 16%;
      }
      /******Evidence Media*********/

    `}

  ${mediaQuery('md')`
      flex-wrap: wrap;
      max-height: 526px;
      min-height: 526px;
      min-width: 852px;
      max-width: 920px;

      .evidenceModal--nameSection{
        padding: 0px;
        justify-content:center;
        
        & > h3{
          max-width:530px;
        }
      }

      .evidenceModal--mediaSection{
        min-height: 340px;
      }

      .evidenceModal--informationSection{
        padding: 3% 20px 20px 20px;
        flex-wrap:wrap;


          & > .evidenceModal--mediaSliderWrapper{
            &.em--mediaSliderWrapper{
              width: 90%;
            }

            & .em--noContent{
              font-size: 20px;
              &--comments{
                margin: 50% 0;
              }
            }
          }
      }

      & > .evidenceModal--commentsSection{
        background-color: #ebebeb;
        min-height: inherit;
        padding: 20px 16px 12px 16px;
        
        & > .em-mediaSliderTitle{
            color: #000000;
            font-size: 21px;
            text-decoration: none solid rgb(0, 0, 0);
            font-weight: bold;
            margin-bottom: 32px;
        }
      
        & > .evideceModal--commentsWrapper{
          max-height: 428px;
          overflow-y: auto;

          & > .em--commentWrapper{
            border-bottom: 1px solid #8c8c8c; 
            font-size: 14px;
            color: #404040;
            text-decoration: none solid rgb(64, 64, 64);
            padding: 12px 0;
            word-break: break-all;
            
            &:first-child{
              padding-top: 0;
            }

            &:last-child{
              border-bottom: initial;
            }
          }
          /* width */
          &::-webkit-scrollbar {
            background-color: #dadada;
            width: 8px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
          }
          
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #616161; 
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #404040;
          }
        }
      }

      /******Evidence Media*********/
      & .evidenceMedia--image-wrapper {
        margin: 0% auto;
        max-width: 340px;
        /*height: 319px;*/
        height: 280px;
      }

      & .evidenceMedia--video-wrapper{
        margin: 8% 0px;
        padding-bottom: 56.25%;
      }

      & .evidenceMedia--audio-wrapper{
        margin-top: 28%;
      }
      /******Evidence Media*********/
    `}

${mediaQuery('lg')`
      max-height: 560px;
      min-height: 560px;
      min-width: 1100px;
      max-width: 980px;

      .evidenceModal--nameSection{
        height: 78px;
        min-height: 78px;
        padding: 0px;
        justify-content:center;
      }

      .evidenceModal--mediaSection{
        min-height: 360px;
      }

      .evidenceModal--informationSection{
        padding-top: 2%;
        padding-bottom: 0px;
          & > .evidenceModal--mediaSliderWrapper{
            &.em--mediaSliderWrapper{
              width: 80%;
            }

            & .em--SliderItem--Thumbnails{
              height: 72px;
              width: 72px;

              .MuiSvgIcon-root{
                left: 23%;
                top: 21%;
              }
            }
          }
      }

      & > .evidenceModal--commentsSection{
        & > .em-mediaSliderTitle{
          font-size: 21px;
          text-decoration: none solid rgb(0, 0, 0);
        }

        & > .evideceModal--commentsWrapper{
          max-height: 462px;

          & > .em--commentWrapper{
            border-bottom-width: 2px;
            font-size: 14px;
            color: #404040;
            text-decoration: none solid rgb(64, 64, 64);
            padding: 14px 0;
          }
        }
      }

      /******Evidence Media*********/
      & .evidenceMedia--image-wrapper {
        max-width: 410px;
        height: 337px;
      }

      & .evidenceMedia--video-wrapper{
        margin: 4% 0px;
        padding-bottom: 36.25%;
      }

      & .evidenceMedia--audio-wrapper{
        margin-top: 17%;
      }

      /******Evidence Media*********/

    `}

${mediaQuery('xl')`
      max-height: 600px;
      min-height: 600px;
      max-width: 1280px;

      .evidenceModal--mediaSection{
        min-height: 376px;
      }
    `}
`

export const SliderIconButton = styled(IconButton)`
  background-color: transparent;
  color: #121212;
  position: absolute;
  top: 20%;

  &.MuiIconButton-root {
    padding: 0;
  }
  .MuiSvgIcon-root {
    font-size: 5rem;
    height: 0.5em;
    width: 0.5em;
  }

  &.sliderArrow--left {
    left: calc(1.2rem * -2);
  }

  &.sliderArrow--right {
    right: calc(1.2rem * -2);
  }

  &.sliderArrow--media {
    top: 2%;
  }

  ${mediaQuery('sm')`
      top: 10%;
    `}

  ${mediaQuery('md')`
      top: 0%;
      .MuiSvgIcon-root{
        font-size: 7rem;
      }

      &.sliderArrow--left{
        left: calc(1.5rem*-2);
      }

      &.sliderArrow--right{
        right: calc(1.5rem*-2);
      }
    `}
`
