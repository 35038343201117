import React from 'react'
import PropTypes from 'prop-types'

import { BasicLoaderStyle, BasicLoaderWrapperStyle } from './styles'

function BasicLoader({ className, label, sizeLoader }) {
  return (
    <BasicLoaderWrapperStyle className={className}>
      <BasicLoaderStyle size={sizeLoader} />
      <span>{label}</span>
    </BasicLoaderWrapperStyle>
  )
}

BasicLoader.propTypes = {
  className: PropTypes.string,
  labal: PropTypes.string,
  sizeLoader: PropTypes.string,
}

export { BasicLoader }
