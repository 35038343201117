import { createGlobalStyle } from 'styled-components'
import { defineFonts } from './fonts'
import { mediaQuery } from './helpers/mediaQuery'

import backgroundSquareOne from '../assets/images/background-square-one.png'
import backgroundSquareTwo from '../assets/images/background-square-two.png'

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    font-family: 'Lato', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
  }


  ${() => defineFonts()}

  ul, li, h1, h2, h3, p, button, figure { 
    margin: 0;
    padding: 0;
  }
  
  ul {
    list-style: none; 
  }

  button {
    background: transparent; 
    border: 0; 
    outline: 0;
  }

  body {
    background: #ffffff;
    height: 100vh;
    margin: 0;
  }

  #app{
  }

  /**Utils Classes*/

  .hidden__visibility{
    visibility: hidden;
  }

  .common--default-background{
    align-items: center;
    background-color: #efeff3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    min-height: 100vh;

    background-image: url(${backgroundSquareOne}),  url(${backgroundSquareTwo});
    background-position: 97% 100px, 4% 97%;
    background-repeat: no-repeat;

  }

  ${mediaQuery('sm')`
      background-position: 80% 100px, 32% 97%
    `}

`
