import { memo } from 'react'
import { NotesHeader, NotesRoot } from './styles'
import { useTranslation } from 'react-i18next'

function NotesAboutEstimate({ notes }) {
  const { t } = useTranslation()

  if (!notes || notes.length === 0) return null

  return (
    <NotesRoot>
      <NotesHeader>
        {t('inspectionItems.informationSection.estimateNotes')}
      </NotesHeader>
      {notes.map((v, i) => (
        <p key={i}>{v}</p>
      ))}
    </NotesRoot>
  )
}

export default memo(NotesAboutEstimate)
