function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  return parseFloat(num.toString().match(re)[0])
}

function calcTaxes(taxPercentage, total) {
  if (!taxPercentage) {
    return 0
  }
  return (taxPercentage / 100) * total
}
export { calcTaxes, toFixed }
