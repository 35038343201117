import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, Location } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Divider, List, ListItem, ListItemIcon } from '@material-ui/core'
import { Stop as StopIcon } from '@material-ui/icons'

import { LabelPrimary } from '../Labels'
import { SideMenuStyle } from './styles'

import { RepairShopContainer } from '../../containers/repairShop.container'
import { withConnect } from '../../HOC/withConnect'

function SideMenuSateLess({ toggleDrawer, open = false, containers }) {
  const [openMenu, setOpenMenu] = useState(false)
  const { t } = useTranslation()
  const [RepairShopContainer] = containers

  useEffect(() => {
    setOpenMenu(open)
  }, [open])

  const onClose = () => {
    setOpenMenu(false)
    toggleDrawer(false)
  }
  const onOpen = () => {
    setOpenMenu(true)
    toggleDrawer(true)
  }
  return (
    <Location>
      {({ location }) => {
        return (
          <SideMenuStyle
            anchor="left"
            onClose={onClose}
            open={openMenu}
            onOpen={onOpen}
          >
            <div className="sideMenu--header">
              <LabelPrimary size="s3">
                {RepairShopContainer.state.name}
              </LabelPrimary>
            </div>
            <Divider />
            <List
              aria-label="Side Menu"
              className="sideMenu--list"
              component="nav"
            >
              <ListItem
                button
                disableGutters
                onClick={onClose}
                component={Link}
                to={
                  '/digital-repair-order/workshop-information/' +
                  location.search
                }
              >
                <ListItemIcon>
                  <StopIcon fontSize="small" />
                </ListItemIcon>
                <LabelPrimary className="sideMenu--list--itemLabel">
                  {t('common.menu.items.workshopInformation')}
                </LabelPrimary>
              </ListItem>
            </List>
          </SideMenuStyle>
        )
      }}
    </Location>
  )
}

SideMenuSateLess.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  containers: PropTypes.array,
}

export const SideMenu = withConnect([RepairShopContainer])(SideMenuSateLess)
