import styled from 'styled-components'

import { breakpoints } from '../../styles/helpers/mediaQuery'

const sizeStyle = {
  small: {
    key: 'small',
    style: {
      height: {
        xs: '40px',
        sm: '48px',
        md: '56px',
        lg: '64px',
      },
      width: {
        xs: '40px',
        sm: '48px',
        md: '56px',
        lg: '64px',
      },
    },
  },
}

function setSizeStyles({ size }) {
  return {
    height: sizeStyle[size].style.height,
    width: sizeStyle[size].style.width,
  }
}

export const ThumbnailImageStyle = styled.figure.attrs(setSizeStyles)`
  background-color: transparent;
  border-radius: 10px;
  margin: 0 4px;
  height: ${(props) => props.height.xs};
  position: relative;
  width: ${(props) => props.width.xs};

  img {
    border-radius: 10px;
    height: 100%;
    opacity: 0.8;
    width: 100%;
  }

  .MuiSvgIcon-root {
    color: #ffffff;
    left: 25%;
    position: absolute;
    top: 25%;
  }

  &.thumbnail--noBackground {
    background-color: #8188af;
  }

  &.thumbnailColor--primary {
    .MuiSvgIcon-root {
      color: #121a41;
    }
  }

  &.thumbnail--small {
    font-size: 12px;
    height: 20px;
    width: 20px;
    .MuiSvgIcon-root {
      left: 0px;
      height: 100%;
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    height: ${(props) => props.height.sm};
    width: ${(props) => props.width.sm};

    .MuiSvgIcon-root {
      left: 17%;
      font-size: 32px;
      top: 17%;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    height: ${(props) => props.height.md};
    width: ${(props) => props.width.md};

    .MuiSvgIcon-root {
      left: 19%;
      font-size: 36px;
      top: 18%;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    height: ${(props) => props.height.lg};
    width: ${(props) => props.width.lg};

    .MuiSvgIcon-root {
      left: 16%;
      font-size: 40px;
      top: 19%;
    }
  }
`
