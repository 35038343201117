import { Container } from 'unstated'
import { AdditionalNoteService } from '../services/AdditionalNote.service'

class AdditionalNoteContainer extends Container {
  constructor() {
    super()

    this.additionalNoteService = new AdditionalNoteService()
    this.state = {
      additionalNotes: [],
    }
  }

  getData(roNumberEncode) {
    this.setState({ loadingData: !this.state.loadedData })
    return this.additionalNoteService
      .getAdditionalNotes(roNumberEncode)
      .then((data) => {
        this.setState({
          additionalNotes: data,
          loadedData: !this.state.loadedData,
          loadingData: !this.state.loadedData,
        })
      })
      .finally(() => {
        this.setState({ loadingData: !this.state.loadedData })
      })
  }
}

export { AdditionalNoteContainer }
