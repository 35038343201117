import { Container } from 'unstated'
import { navigate } from '@reach/router'

import { RepairOrderService } from '../services/RepairOrder.service'

import { toFixed } from '../utils/calc'

const NOT_FOUND = '/digital-repair-order/not-found'
class RepairOrderContainer extends Container {
  constructor(repairShopContainer, inspectionItemContainer) {
    super()

    this.repairOrderService = new RepairOrderService(repairShopContainer)
    this.inspectionItemContainer = inspectionItemContainer
    this.repairShopContainer = repairShopContainer

    this.state = {
      lastName: '',
      landlinePhoneNumber: '',
      mobilePhoneNumber: '',
      plates: '',
      year: '',
      templateId: 0,
      firstName: '',
      showEstimateToConsumer: false,
      repairOrderNumber: '',
      model: '',
      vin: '',
      make: '',
      email: '',
      mileage: '',
      repairOrderId: '',
      roNumberEncode: '',
      repairShopId: 0,
      makeLogoUrl: '',
      teamMember: {
        mobileNumber: '',
        landlineNumber: '',
      },
      repairsAmount: {
        good: 0,
        urgent: 0,
        suggested: 0,
      },
      totalApproved: 0.0,
      inspectionItemsSelectedTotal: 0.0,
      inspectionItemsSelected: [],
      apiToken: '',
      loadedData: false,
      loadingData: false,
      loadingInspectionItemsToApprove: false,
      loadedInspectionItemsToApprove: false,
    }
  }

  getData(roNumberEncode) {
    this.setState({ loadingData: !this.state.loadedData })
    return this.repairOrderService
      .getFullRepairOrder(roNumberEncode)
      .then((fullRepairOrderResponse) =>
        this.setState({
          ...fullRepairOrderResponse,
          roNumberEncode,
          loadedData: !this.state.loadedData,
          loadingData: !this.state.loadedData,
        })
      )
      .then(() => this.state)
      .catch(() => navigate(NOT_FOUND, { replace: true }))
      .finally(() => this.setState({ loadingData: !this.state.loadedData }))
  }

  addInspectionItemSelected(inspectiomItem) {
    this.setState({
      inspectionItemsSelected: [
        ...this.state.inspectionItemsSelected,
        inspectiomItem,
      ],
      inspectionItemsSelectedTotal: toFixed(
        this.state.inspectionItemsSelectedTotal + inspectiomItem.total,
        2
      ),
    })
  }

  removeInspectionItemSelected(inspectiomItem) {
    const total = this.state.inspectionItemsSelectedTotal - inspectiomItem.total
    const itemsSelected = this.state.inspectionItemsSelected.filter(
      (item) => item.id !== inspectiomItem.id
    )

    this.setState({
      inspectionItemsSelected: [...itemsSelected],
      inspectionItemsSelectedTotal: toFixed(total, 2),
    })
  }

  approveInspectionItems() {
    this.setState({
      loadingInspectionItemsToApprove: !this.state
        .loadingInspectionItemsToApprove,
    })

    const approvedItems = this.state.inspectionItemsSelected.map(
      (inspectionItem) => inspectionItem.masterItemId
    )

    this.repairOrderService
      .approveInspectionItem(
        this.state.apiToken,
        this.state.repairOrderId,
        approvedItems
      )
      .then((totalApproved) => {
        this.inspectionItemContainer.changeApprovalStatus(
          this.state.inspectionItemsSelected,
          this.inspectionItemContainer.approvalStatus.approved
        )
        this.setState({
          totalApproved: totalApproved + this.state.totalApproved,
          loadedInspectionItemsToApprove: !this.state
            .loadedInspectionItemsToApprove,
        })
      })
      .finally(() => {
        this.setState({
          loadingInspectionItemsToApprove: !this.state
            .loadingInspectionItemsToApprove,
        })
      })
  }

  countTotalRepairsApproved(repairs) {
    repairs = repairs || this.state.repairs
    this.repairOrderService
      .countTotalRepairsApproved(repairs)
      .then((totalApproved) => this.setState({ totalApproved }))
      .catch(() => this.setState({ totalApproved: 0 }))
  }

  getTotalRepairsApproved(roNumberEncode) {
    roNumberEncode = roNumberEncode || this.state.roNumberEncode

    this.repairOrderService
      .getTotalRepairsApproved(roNumberEncode)
      .then((totalApproved) => {
        this.setState({ totalApproved })
      })
      .catch(() => this.setState({ totalApproved: 0 }))
  }

  clearInspectionItemsSelected() {
    this.setState({
      inspectionItemsSelected: [],
      inspectionItemsSelectedTotal: 0.0,
      loadedInspectionItemsToApprove: !this.state
        .loadedInspectionItemsToApprove,
    })
  }
}

export { RepairOrderContainer }
