import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
// import { LocalPhone as LocalPhoneIcon } from '@material-ui/icons'

// import { ChipButton } from '../../../../components/ChipButton'
import { LabelPrimary } from '../../../../components/Labels'

import { TotalInfoCardStyle } from './styles'

function TotalInfoCard({
  buttonLabel,
  mainLabel,
  phoneLabel,
  phoneNumber,
  totalAmount,
}) {
  // const [showPhoneNumber, setShowPhoneNumber] = useState(false)

  // const handlerShowPhoneNumber = () => setShowPhoneNumber(!showPhoneNumber)

  return (
    <TotalInfoCardStyle container direction="column" wrap="nowrap">
      <Grid className="totalInfoCardStyle--approvalTotalSection" item>
        <LabelPrimary>{mainLabel}</LabelPrimary>
        <LabelPrimary
          size="s3"
          style={{ visibility: totalAmount ? 'visible' : 'hidden' }}
        >
          {totalAmount}
        </LabelPrimary>
      </Grid>
      {/* TODO: Temporal por ticket CM-4513 */}
      {/* <Grid className='totalInfoCardStyle--contactSection' item>
        <p className={(showPhoneNumber) ? '' : 'hidden__visibility'}>
          <span>{phoneLabel} </span>{phoneNumber}
        </p>
        <ChipButton
          variant='contained'
          size='small'
          startIcon={<LocalPhoneIcon />}
          onClick={handlerShowPhoneNumber}
        >
          {buttonLabel}
        </ChipButton>
      </Grid> */}
    </TotalInfoCardStyle>
  )
}

TotalInfoCard.propTypes = {
  buttonLabel: PropTypes.string,
  mainLabel: PropTypes.string,
  phoneLabel: PropTypes.string,
  phoneNumber: PropTypes.string,
  totalAmount: PropTypes.number,
}

export { TotalInfoCard }
