import React from 'react'
import { Subscribe } from 'unstated'

function withConnect(_containers = []) {
  return (Component) => {
    return (props) => (
      <Subscribe to={_containers}>
        {(...containers) => <Component {...props} containers={containers} />}
      </Subscribe>
    )
  }
}

export { withConnect }
