import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { breakpoints } from '../../styles/helpers/mediaQuery'
import backgroundSquareOne from '../../assets/images/background-square-one.png'
import backgroundSquareTwo from '../../assets/images/background-square-two.png'

export const InspectionItemsStyle = styled(Grid)`
  padding: 0 16px;

  & .inspectionItems {
    &--navBar {
      margin: 24px 0 14px 0;
    }
    &--informationSection {
      padding-top: 18px;
    }
    &--typeCarSection {
      margin-bottom: 8px;
    }
    &--amountApprovedSection {
      margin-bottom: 24px;
    }
    &--listsSection {
      padding-bottom: 75px;
      & > div:last-child {
        padding-bottom: 72px;
      }
      &--basicLoader {
        margin-top: 5%;
      }
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    background-image: url(${backgroundSquareOne}), url(${backgroundSquareTwo});
    background-position: 97% 30px, 9% 97%;
    background-repeat: no-repeat;
    padding: 0px;

    & .inspectionItems--informationSection {
      background-color: #ffffff;
      border-bottom-right-radius: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 32px;
      min-height: 84px;
      padding: 16px;
      position: sticky;
      top: 60px;
      z-index: 99;

      > div {
        border-left: 1px solid #8188af;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 12px;
        padding: 0px 16px;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-left: none;
          margin-bottom: 0;
        }
      }
    }

    & .inspectionItems {
      &--amountApprovedSection {
        margin-bottom: 0px;
      }

      &--listsSection {
        & > div {
          margin-bottom: 8px;
          padding: 0 16px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    & .inspectionItems--informationSection {
      position: sticky;
      top: 72px;
      z-index: 99;
      justify-content: flex-start;

      > div {
        display: initial;
        margin-bottom: initial;

        & > p:first-child {
          font-size: 16px;
        }
        & > p:last-child {
          font-size: 20px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    & .inspectionItems--informationSection {
      position: sticky;
      top: 72px;
      z-index: 99;
      justify-content: center;
    }
  }
`
