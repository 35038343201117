import styled from 'styled-components'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@material-ui/core'

import { breakpoints, mediaQuery } from '../../../../styles/helpers/mediaQuery'

const priorityColor = {
  urgent: '#e84661',
  good: '#25bc73',
  suggested: '#dbb012',
}

export const InspectionItemsContainerStyle = styled(Grid)`
  margin-top: 16px;
  
  ${mediaQuery('sm')`
      justify-content: center;
    `}
  @media (max-width: 800px) and (min-width: ${breakpoints.sm}px){
    flex-direction: column;
    align-items: center;
  }

  ${mediaQuery('md')`
      justify-content: flex-start;
    `}

  ${mediaQuery('lg')`
      justify-content: center;
    `}
`
export const AcordionTitleSectionStyled = styled.div`
  display: flex;

  & .accordionTitleSection-- {
    &items-amount {
      color: #f4f4f9;
      background-color: ${(props) => priorityColor[props.priority]};
      height: 72px;
      font-family: 'Helvetica';
      font-size: 30px;
      font-weight: bold;
      margin-right: 28px;
      padding: 17px 0;
      text-align: center;
      width: 103px;
    }
    &title {
      color: #17152a;
      font-family: 'Lato';
      font-size: 24px;
      font-weight: bold;
      min-width: 300px;
      padding-top: 16px;
    }
    &expanded-icon {
      padding-top: 20px;

      & > .MuiSvgIcon-fontSizeSmall {
        color: #121212;
        font-size: 32px;
      }
    }
  }

  ${mediaQuery('md')`
    & .accordionTitleSection--{
      &items-amount{
        height: 64px;
        font-size: 24px;
        margin-right: 20px;
        width: 80px;
      }
      &title{
        
        
        font-size: 20px;
      
        min-width: 252px;
        
      }
      &expanded-icon{
        padding-top: 17px;

        & > .MuiSvgIcon-fontSizeSmall {
            font-size: 28px;
        }
      }
    }
    `}
`

export const AccordionSummaryStyle = styled(AccordionSummary)`
  padding: 0;

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`
export const AccordionDetailsStyle = styled(AccordionDetails)`
  background-color: #efeff3;
`

export const AccordionStyle = styled(Accordion)`
  &.MuiAccordion-root.Mui-expanded {
    box-shadow: initial;
  }
`
