import React from 'react'
import PropTypes from 'prop-types'
import { Slide } from '@material-ui/core'

import { ModalStyle } from './styles'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function Modal({ children, ...props }) {
  return (
    <ModalStyle TransitionComponent={Transition} {...props}>
      {children}
    </ModalStyle>
  )
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
}

export { Modal }
